import {useEffect, useState} from "react";
import './AlertsSettingsTab.scss';
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import DeleteIcon from '@mui/icons-material/Delete';
import TempAlertDisableAccordion from "../TempAlertDisableAccordion/TempAlertDisableAccordion";
import {getNodeContext, shortString} from "../../../../utils/utils";
import {deleteAlertSilenceRegime, saveNewAlertSilenceRegime} from "../../../../api/api";
import {DateTime} from "luxon";
import {showSnackbar} from "../../../../utils/SnackbarUtils";


const AlertsSettingsTab = props => {
    const [site, setSite] = useState(props.site)
    const [t] = useTranslation()
    const [isAccordionExpanded, setIsAccordionExpanded] = useState(false)
    const poolTitles = getNodeContext().dashboardNames
    const poolIds = getNodeContext().poolIds
    const siteId = getNodeContext().siteId
    const pools = poolTitles.map((title, index) => {
        return {title, id: poolIds[index]}
    })
    const [activeSilences, setActiveSilences] = useState([])
const isAdmin= getNodeContext().permissions === 'administrator'
    async function fetchActiveSilences() {
        const freshSite = await props.fetchSite(siteId)
        setSite(freshSite)
        setActiveSilences(freshSite.settings.temporaryAlertDisable || [])
    }

    useEffect(() => {
        fetchActiveSilences()
    }, []);

    async function saveNewRegime(regime) {
        try {
            const startTime = DateTime.fromJSDate(regime.selectedDate).plus({minutes: regime.selectedTime}).toISO()
            const endTime = DateTime.fromJSDate(regime.selectedDate).plus({minutes: regime.selectedTime}).plus({minutes: regime.selectedDuration}).toISO()
            await saveNewAlertSilenceRegime(siteId, {
                alertSilence: {
                    startTime,
                    endTime,
                    poolName: regime.selectedPool,
                    poolId: regime.selectedPoolId,
                    reason: regime.reason
                }
            })
            fetchActiveSilences()
            const m = <div>
                <div>
                    {t('alertSilenceCreated')}
                </div>
                <div>{t('changesApply')} <u>{t('within10Min')}</u>
                </div>
            </div>
            showSnackbar(m, 'success')
            return true

        } catch (e) {
            if (e.response && e.response.status === 400) {
                showSnackbar(e.response.data.message, 'error')
            } else {
                showSnackbar(t('alertSilenceCreatingFailed'), 'error')
            }
        }
    }

    async function deleteActiveSilence({startTime, poolName, reason, endTime}) {
        try {
            if (!isAdmin) {
                return
            }
            await deleteAlertSilenceRegime(siteId, {startTime, poolName, reason, endTime})
            fetchActiveSilences()
            const m = <div>
                <div>
                    {t('alertSilenceDeleted')}
                </div>
                <div>{t('changesApply')}
                    <u>{t('within10Min')}</u>
                </div>
            </div>
            showSnackbar(m, 'success')
        } catch (ex) {
            showSnackbar(t('alertSilenceDeletingFailed'), 'error')
        }
    }

    return (<div id={'alerts-settings-tab'}>
        <div className={'pane'}>
            <div className={'title'}>
                {t('alertsSettings')}
            </div>
            {isAdmin && <TempAlertDisableAccordion
                pools={pools || []}
                submit={saveNewRegime}
                handleChange={(event, expanded) => {
                    setIsAccordionExpanded(!!expanded)
                }}
            />}


            <div className={'alert-mask-pane'}>
                <div className={'title'}>{t('scheduledSilencingSessions')}</div>
                <table>
                    <thead>
                    <tr className={'header-row'}>
                        <th className={'length-25'}>{t('')}</th>
                        <th>{t('pool')}</th>
                        <th>{t('date')}</th>
                        <th>{t('start')}</th>
                        <th>{t('end')}</th>
                        <th>{t('reason')}</th>
                        <th>{t('action')}</th>
                    </tr>
                    <tr/>
                    </thead>
                    <tbody>
                    {activeSilences
                        .filter(regime => {
                            return DateTime.fromISO(regime.endTime) > DateTime.now().setZone(site.timezone).startOf('day')
                        })
                        .sort((s, e) => {
                            return DateTime.fromISO(s.startTime) - DateTime.fromISO(e.startTime)
                        })
                        .map((regime, index) => {
                            const startTime = DateTime.fromISO(regime.startTime).toFormat('HH:mm')
                            const endTime = DateTime.fromISO(regime.endTime).toFormat('HH:mm')
                            const poolName = shortString(regime.poolName, 25)
                            const dateS = regime.startTime
                            const dateE = regime.endTime
                            const reason = shortString(regime.reason, 30)
                            return (<tr className={'alert-mask'} key={index}>
                                <td>{index + 1}.</td>
                                <td>{poolName}</td>
                                <td>{DateTime.fromISO(regime.startTime).toFormat('ccc dd/LL/yy')}</td>
                                <td>{startTime}</td>
                                <td>{endTime}</td>
                                <td>{reason}</td>
                                <td className={'center-items length25'}>
                                    <DeleteIcon
                                        disabled={!isAdmin}
                                        onClick={() => {
                                        deleteActiveSilence({startTime: dateS, endTime: dateE, poolName, reason})
                                    }} className={`delete-icon ${isAdmin? '':'disabled'}`}/>
                                </td>
                            </tr>)
                        })}
                    </tbody>
                </table>
            </div>

        </div>

    </div>)
}

AlertsSettingsTab.propTypes = {
    activateSnackbar: PropTypes.func.isRequired, site: PropTypes.object.isRequired, fetchSite: PropTypes.func.isRequired
}
export default AlertsSettingsTab


