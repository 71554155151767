import './PoolDiagramTileStatic.scss'
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import PoolCapacityTileStatic from "./PoolCapacityTileStatic/PoolCapacityTileStatic";
import {useEffect, useState} from "react";
import {transformLinearVector} from "../../utils/LinearCalcHelper";
import {envMap, mockDataLocations} from "../../utils/utils";

const url = process.env.REACT_APP_IMAGE_STORAGE_PATH + 'pools/' + envMap[process.env.NODE_ENV] + '/'
const transformationDimensions = {height: 710, width: 1380}
const height = 250
const layoutDimensions = {height, width: height * transformationDimensions.width / transformationDimensions.height}
const adjustmentHeight = 0;
const adjustmentWidth = -10;
const PoolDiagramTileStatic = props => {
    const [capacity] = useState(props.capacity || 0)
    const [transformation] = useState(props.transformation && props.transformation.length && props.transformation || [[], [], []])
    const [locations] = useState(props.testMode ? mockDataLocations : props.locations || [])
    const [error, setError] = useState(false)
    const {t} = useTranslation();
    const scale = {
        x: (layoutDimensions.width) / transformationDimensions.width,
        y: (layoutDimensions.height) / transformationDimensions.height
    }
    useEffect(() => {

    }, [props.locations, props.testMode]);

    function handleImageError() {
        setError(true)
        const img = document.getElementById('pool-image')
        img.src = url + `pool_id_0.svg`
    }

    const swimmersLocationsTransformed = locations.map(l => transformLinearVector(transformation, l))

    return <div id={'pool-diagram-tile-static'}>
        <div className={'pool-tile-title'}>{t('swimmersLocations')}</div>
        <div className={'inner-box-pd'}>
            <PoolCapacityTileStatic data={{capacity, locations}}/>
            <div className={'pool-image-container'}>
                <img id={'pool-image'} className={'img-pool-st'} src={url + 'pool_id_' + props.poolId + '.svg'}
                     onError={error ? () => {
                     } : handleImageError} alt="Pool-Image"/>
                <div id={'pool-canvas'} className={'swimmers-layout'}>
                    {swimmersLocationsTransformed.map((coordinate, index) => {
                        let addClass = ''
                        let top = adjustmentHeight + (scale.y * coordinate.y)
                        let left = adjustmentWidth + (scale.x * coordinate.x)
                        if (top > layoutDimensions.height) {
                            top = layoutDimensions.height
                        }
                        if (left > layoutDimensions.width) {
                            left = layoutDimensions.width
                        }
                        if (left < 0) {
                            left = 0
                        }
                        if (top < 0) {
                            top = 0
                        }
                        return <div key={Math.random() + '-key'} className={`dot ${addClass}`} style={{
                            top: `${top}px`, left: `${left}px`, backgroundColor: props.swimmerDotColor || '#57A6DB'
                        }}></div>

                    })}
                </div>
            </div>
        </div>

    </div>
}
PoolDiagramTileStatic.propTypes = {
    capacity: (props, propName) => (props[propName] < 0 || props[propName] > 1) && new Error('Invalid value provided, capacity should be float 0-1 '),
    locations: PropTypes.array.isRequired,
    poolId: PropTypes.number.isRequired,
    transformation: PropTypes.array.isRequired,
}
export default PoolDiagramTileStatic