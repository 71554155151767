import './SiteSettings.scss'
import {useTranslation} from "react-i18next";
import {Switch, TextField} from "@mui/material";
import {useEffect, useState} from "react";
import {fetchSite} from "../../../../api/api";
import {getCountryCodeByName, getNodeContext, getNodeToken, getOrgCountry, handleLogout} from "../../../../utils/utils";
import PropTypes from "prop-types";
import axios from "axios";
import Button from "@mui/material/Button";
import {showSnackbar} from "../../../../utils/SnackbarUtils";
import * as EmailValidator from "email-validator";
import {isValidNumber} from "libphonenumber-js";
import {defaultCountries, PhoneInput} from "react-international-phone";

const SiteSettings = props => {

    const [t] = useTranslation();
    const [site, setSite] = useState({settings: {contact: {}}})
    const [title, setTitle] = useState('')
    const [originalTitle, setOriginalTitle] = useState('')
    const [weeklyReportSubscription, setWeeklyReportSubscription] = useState(false)
    const [email, setEmail] = useState('')
    const [emailOriginal, setOriginalEmail] = useState('')
    const [emailError, setEmailError] = useState('')
    const [phone, setPhone] = useState()
    const [phoneError, setPhoneError] = useState('')
    const [phoneOriginal, setOriginalPhone] = useState('')
    const [editMode, setEditMode] = useState(false)
    const [titleError, setTitleError] = useState('')
    const [siteManagerNotifications, setSiteManagerNotifications] = useState(false)
    const isAdmin = getNodeContext().permissions === 'administrator'
    const siteId = getNodeContext().siteId
    const fetchSiteInt = () => {
        fetchSite(siteId).then(_site => {
            setSite(_site)
            setWeeklyReportSubscription(!!_site.settings.weeklyReportSubscription)
            setTitle(_site.title)
            setPhone(_site.settings.contact && _site.settings.contact.phone || '')
            setOriginalTitle(_site.title)
            setEmail(_site.settings.contact && _site.settings.contact.email || '')
            setOriginalEmail(_site.settings.contact && _site.settings.contact.email || '')
            setOriginalPhone(_site.settings.contact && _site.settings.contact.phone || '')
            setSiteManagerNotifications(!!_site.settings.siteManagerNotifications)
        })
    }
    useEffect(() => {
        fetchSiteInt()
    }, [])

    const saveTitle = async () => {
        let isValid = true;
        if (!title) {
            setTitleError(t('mustHaveTitle'))
            isValid = false;

        }
        if (!isValid) return;
        const payload = {
            title,
        }
        setOriginalTitle(title)
        const token = getNodeToken()
        axios.patch(`${process.env.REACT_APP_NODE_BE_PATH}/site/${siteId}`, payload, {
            headers: {'Authorization': `Bearer ${token}`}
        }).then(response => {
            if (200 === response.status) {
                props.activateSnackbar('Saved title')
                showSnackbar(t("siteTitleSaved"), 'success')
            }
        }).catch(ex => {
            if (ex.response && ex.response.status === 401) {
                handleLogout()
            } else {
                showSnackbar(t("errorSavingTitle"), 'error')
            }
            console.log("Error - ", ex)
        })

    }
    const saveSubscription = async (value) => {
        const payload = {
            settings: {
                weeklyReportSubscription: value
            }
        }
        const token = getNodeToken()
        axios.patch(`${process.env.REACT_APP_NODE_BE_PATH}/site/${siteId}`, payload, {
            headers: {'Authorization': `Bearer ${token}`}
        }).then(response => {
            if (200 === response.status) {
                console.log(t("savedSubscription"))
            }
        }).catch(ex => {
            if (ex.response && ex.response.status === 401) {
                handleLogout()
            } else {
                showSnackbar(t("errorSavingSubscription"), 'error')
            }
            console.log("Error - ", ex)
        })

    }
    const saveSiteManagerNotifications = async (value) => {
        const payload = {
            settings: {
                siteManagerNotifications: value
            }
        }
        const token = getNodeToken()
        axios.patch(`${process.env.REACT_APP_NODE_BE_PATH}/site/${siteId}`, payload, {
            headers: {'Authorization': `Bearer ${token}`}
        }).then(response => {
            if (200 === response.status) {
                console.log(t("siteManagerNotifications"))
            }
        }).catch(ex => {
            if (ex.response && ex.response.status === 401) {
                handleLogout()
            } else {
                showSnackbar(t("errorSiteManagerNotifications"), 'error')
            }
            console.log("Error - ", ex)
        })

    }

    const getCountryPrefix = (country) => {
        let code = country || getCountryCodeByName().toLowerCase()
        const c = defaultCountries.find((item) => item[1] === code)
        return c ? c[2] : ""
    }
    const saveContact = async () => {
        if (!email || !EmailValidator.validate(email)) {
            setEmailError(t(email ? 'invalid' : 'required'))
            return
        }
        const code = getCountryPrefix(getCountryCodeByName(getOrgCountry()).toLowerCase())
        if (!phone || `+${code} ` === phone || `+${code}` === phone) {
            setPhoneError(t('required'))
            return
        }
        if (!isValidNumber(phone)) {
            setPhoneError(t('invalid'))
            return
        }
        const payload = {
            settings: {
                contact: {email, phone}
            }
        }
        const token = getNodeToken()
        axios.patch(`${process.env.REACT_APP_NODE_BE_PATH}/site/${siteId}`, payload, {
            headers: {'Authorization': `Bearer ${token}`}
        }).then(response => {
            if (200 === response.status) {
                setOriginalPhone(phone)
                setOriginalEmail(email)
                showSnackbar(t("contactSaved"), 'success')
                setEditMode(false)
            }
        }).catch(ex => {
            if (ex.response && ex.response.status === 401) {
                handleLogout()
            } else {
                showSnackbar(t("errorSavingContact"), 'error')
            }
            console.log("Error - ", ex)
        })

    }
    const processTitleChange = (ev) => {
        const _title = ev.target.value
        if (_title.length <= 35) {
            setTitle(ev.target.value)
        }
        setTitleError('')
    }
    const clearTitleError = () => {
        setTitleError('')
    }

    function processReportSubscriptionChange(value) {
        setWeeklyReportSubscription(value)
        saveSubscription(value)

    }

    function processSiteManagerNotifications(value) {
        setSiteManagerNotifications(value)
        saveSiteManagerNotifications(value)
    }

    function processPhoneChange(value) {
        setPhoneError('')
        setPhone(value)
    }

    function processEmailChange(ev) {
        const {value} = ev.target
        setEmail(value)
    }

    function cancelTitle() {
        setTitle(originalTitle)
        setTitleError('')
    }

    function cancelContactAdd() {
        setEmail(emailOriginal)
        setPhone(phoneOriginal)
        setEmailError('')
        setPhoneError('')
        setEditMode(false)
    }

    return (<div id={'site-settings'}>
        <div className={'form-container'}>
            <div className={'title-edit-container'}>
                <TextField disabled={!isAdmin}
                           sx={{
                               "& .MuiInputBase-input.Mui-disabled": {
                                   WebkitTextFillColor: "#d0d0d0",
                               },
                           }}
                    onFocus={clearTitleError}
                           onChange={processTitleChange}
                           error={!!titleError}
                           focused={!!title}
                           title={title}
                           inputProps={{style: {color: '#EAFBFF'}, autoComplete: "off"}}
                           helperText={titleError}
                           className={'text-input width17vw'}
                           value={title}
                           defaultValue={title}
                           id="filled-basic"
                           label={t('siteTitle')}
                           autoComplete={"off"}
                           variant="outlined" required/>
                <div
                    className={`buttons-container ${title !== originalTitle ? 'expand-horizontal' : 'collapse-horizontal'}`}>
                    <Button style={{
                        color: "#EAFBFF",
                        fontSize: "16px",
                        fontWeight: 400,
                        fontFamily: "Work Sans",
                        textTransform: "none",
                        padding: "5px 15px"
                    }}
                            onClick={cancelTitle}
                            variant={'text'}>{t('cancel')}</Button>
                    <Button disabled={!isAdmin}
                            style={{
                                backgroundColor: "#57A6DB",
                                color: "#00173D",
                                fontWeight: 500,
                                fontSize: "16px",
                                fontFamily: "Work Sans",
                                textTransform: "none",
                                padding: "5px 15px"
                            }}
                            onClick={saveTitle}
                            variant={'contained'}>{t('save')}</Button>
                </div>
            </div>
            <div className={'checkbox-container'}>
                <Switch
                    disabled={!isAdmin}
                    checked={!!weeklyReportSubscription}
                    className={'switch'}
                    onChange={() => processReportSubscriptionChange(!weeklyReportSubscription)}
                />
                <div className={'switch-title'}>{t('weeklyReportSubscription')}</div>
            </div>

            <div className={'checkbox-container'}>
                <Switch
                    disabled={!isAdmin}
                    checked={!!siteManagerNotifications}
                    className={'switch'}
                    onChange={() => processSiteManagerNotifications(!siteManagerNotifications)}
                />
                <div className={'switch-title'}>{t('siteManagerNotifications')}</div>
            </div>
            <div
                className={`contact-container ${weeklyReportSubscription || siteManagerNotifications ? 'expand' : 'collapse'}`}>
                <div className={'container-row'}>
                    <div className={'title'}>{t('contact')}</div>
                </div>
                <div className={'container-row'}>
                    <TextField
                        sx={{
                            "& .MuiInputBase-input.Mui-disabled": {
                                WebkitTextFillColor: "#d0d0d0",
                            },
                        }}
                        disabled={!isAdmin}

                        label={t('email')}
                        defaultValue={email}
                        inputProps={{style: {color: '#EAFBFF'}}}
                        helperText={emailError}
                        className={'text-input width17vw'}
                        value={email}
                        error={!!emailError}
                        onFocus={() => {
                            setEmailError('');
                            setEditMode(true)
                        }}
                        onChange={processEmailChange}
                        focused={!!email}
                    />
                    <div className={'phone-selector-rap'}>
                        <PhoneInput
                            disabled={!isAdmin}
                            className={'phone-selector'}
                            value={phone}
                            forceDialCode
                            onChange={processPhoneChange}
                            inputProps={{
                                onFocus: () => {
                                    setEditMode(true)
                                }
                            }}
                            style={{position: "relative", width: "100%", height: "56px"}}
                            countrySelectorStyleProps={{
                                buttonStyle: {
                                    borderColor: phoneError ? '#f3204a' : '#3D5484', width: '65px'
                                }
                            }}
                            inputStyle={{borderColor: phoneError ? '#f3204a' : '#3D5484'}}
                            defaultCountry={getCountryCodeByName(getOrgCountry()).toLowerCase()}
                        />
                        {phoneError && <p className={'phone-error-msg'}>{phoneError}</p>}
                    </div>

                </div>
                <div className={`container-row justify-right top15 ${editMode ? "expand2" : "collapse2"}`}>
                    <Button style={{
                        color: "#EAFBFF",
                        fontSize: "16px",
                        fontWeight: 400,
                        fontFamily: "Work Sans",
                        textTransform: "none",
                        padding: "5px 15px"
                    }}
                            onClick={cancelContactAdd}
                            variant={'text'}>{t('cancel')}</Button>
                    <Button disabled={!isAdmin}
                            style={{
                                backgroundColor: "#57A6DB",
                                color: "#00173D",
                                fontWeight: 500,
                                fontSize: "16px",
                                fontFamily: "Work Sans",
                                textTransform: "none",
                                padding: "0px 15px"
                            }}
                            onClick={saveContact}
                            variant={'contained'}>{t('saveContact')}</Button>
                </div>

            </div>
        </div>
    </div>)
}
SiteSettings.propTypes = {
    activateSnackbar: PropTypes.func.isRequired, handleSettingsClose: PropTypes.func.isRequired
}
export default SiteSettings


