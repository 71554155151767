import {useTranslation} from "react-i18next";
import React, {useState} from 'react';
import {ReactComponent as LxIcon} from '../../public/images/lx_logo.svg';
import {useParams} from "react-router-dom";
import axios from 'axios';
import {PropTypes} from 'prop-types';
import './ResetPasswordNewCred.scss'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import {Button} from "@mui/material";
import {passwordStrength} from 'check-password-strength'

const ResetPasswordNewCred = props => {

    const [firstPassword, setFirstPassword] = useState('');
    const [secondPassword, setSecondPassword] = useState('');
    const [blankFieldAlert, setBlankFieldAlert] = useState(false);
    const [dontMatchField, setDontMatchField] = useState(false);
    const [sessionExpired, setSessionExpired] = useState(false);
    const [isVisibleFirst, setIsVisibleFirst] = useState(false);
    const [isVisibleSecond, setIsVisibleSecond] = useState(false);
    const {token} = useParams();
    const [t] = useTranslation();

    const handleCode = async (e) => {
        if (firstPassword.length === 0 || secondPassword.length === 0) {
            setBlankFieldAlert(true);
            return;
        }
        if (firstPassword !== secondPassword) {
            setDontMatchField(true);
            return;
        }
        if (firstPassword === secondPassword  && firstPassword.length >=8) {
            const url = `${process.env.REACT_APP_NODE_BE_PATH}/reset-password-token/reset-password`;
            const body = {password: firstPassword}
            try {
                await axios.patch(url, body, {
                    headers: {'Authorization': `Bearer ${token}`}
                });
                props.setIsPasswordEntered(true)
            } catch (e) {
                setSessionExpired(true);
                console.log("Error resetting password ", e.response)
                return e.response.status
            }
        }
    }

    const handleFirstPassword = (e) => {
        let password = e.target.value
        // /^[a-zA-Z0-9!@#%^&*()_+Q?\/\\|]{3,120}$/gm
        password = password.replaceAll('$', '')
            .replaceAll('\"', '')
            .replaceAll('`', '')
            .replaceAll('\'', '')
            .replaceAll(':', '')
            .replaceAll(';', '')
            .replaceAll('}', '')
            .replaceAll('{', '')
            .replaceAll('>', '')
            .replaceAll('<', '')
            .replaceAll('[', '')
            .replaceAll(']', '')
            .replaceAll('±', '')
            .replaceAll('§', '')
            .replaceAll('~', '')
        setBlankFieldAlert(false);
        setDontMatchField(false);
        setFirstPassword(password)
    }

    const handleSecondPassword = (e) => {
        setBlankFieldAlert(false);
        if (firstPassword !== e.target.value) {
            setDontMatchField(true);
        } else {
            setDontMatchField(false);
        }
        setSecondPassword(e.target.value)
    }

    const handleVisibleFirst = () => {
        setIsVisibleFirst(!isVisibleFirst)
    }

    const handleVisibleSecond = () => {
        setIsVisibleSecond(!isVisibleSecond)
    }

    return (
        <div id={'reset-password-new-cred-page'}>
            <div className='header'/>


            <div className={'text-pane'}>
                <div className='title-text'>
                    {t('resetPassword')}
                </div>
                <div className='explain-text'>
                    {t('resetPasswordNewPasswordsExplainText')}
                </div>
            </div>
            <div>
                <div className={'input-container'}
                     title={'Minimum 8 characters. Use a-z, A-Z, 0-9 and special characters: !@#%^&*()_+Q?/|\\'}>
                    <input maxLength={120} value={firstPassword} className={'password-input'} onChange={handleFirstPassword} required
                           type={isVisibleFirst ? "text" : "password"}/>
                    <span className={'floating-label'}>
                    {t('enterPassword')}
                </span>
                    <span className={'floating-visibility'} onClick={handleVisibleFirst}>
                    {isVisibleFirst ? <VisibilityOffOutlinedIcon/> : <VisibilityOutlinedIcon/>}
                </span>
                </div>

                {firstPassword && <div
                    className={'password-strong-label ' + passwordStrength(firstPassword).value}>{firstPassword.length < 8 ? "Too short" : passwordStrength(firstPassword).value}</div>
                }
                <div className={'input-container top40'}>
                    <input maxLength={120} value={secondPassword} className={'password-input'} onChange={handleSecondPassword} required
                           type={isVisibleSecond ? "text" : "password"}/>
                    <span className={'floating-label'}>{t('confirmPassword')}</span>
                    <span className={'floating-visibility'} onClick={handleVisibleSecond}>
                    {isVisibleSecond ? <VisibilityOffOutlinedIcon/> : <VisibilityOutlinedIcon/>}
                </span>
                    {blankFieldAlert &&
                        <div className={'username-error'}>{t('Please enter the password in both input boxes.')}</div>}
                    {dontMatchField && <div className={'username-error'}>{t('Passwords don\'t match')}</div>}
                    {sessionExpired && <div className={'username-error'}>
                        {t('sessionExpired')}
                        <a href={window.location.href.substring(0, window.location.href.indexOf('reset_password')) + 'reset_password'}>{window.location.href.substring(0, window.location.href.indexOf('reset_password'))}reset_password</a>
                    </div>}
                </div>
            </div>
            <Button  onClick={handleCode} disabled={ secondPassword.length<8|| firstPassword.length<8 || secondPassword !== firstPassword}  className={'back-button'}>
                <div className={'button-text'}>
                    {t('changePassword')}
                </div>
            </Button>

            <div className={'bottom-label'}>
                <div className={'prefix'}>{t('poweredBy')}</div>
                <LxIcon className={'svg'}/>
            </div>
        </div>)
}
ResetPasswordNewCred.propTypes = {
    setIsPasswordEntered: PropTypes.func
}


const ResetPasswordFinish = props => {
    const [t] = useTranslation()
    const goBack = () => {
        window.location.assign('/')
    }
    return (
        <div id={'reset-password-new-cred-page'}>
            <div className={'header'}/>
            <div className={'text-pane'}>
                <div className={'title-text'}>
                    {t('passwordCreationSuccess')}
                </div>
                <div className='explain-text'>
                    {t('resetPasswordFinishExplainText')}
                </div>
            </div>

            <Button className={'back-button'} onClick={goBack}>
                <div className={'button-text'}>
                    {t('backToLoginPage')}
                </div>
            </Button>
            <div className={'bottom-label'}>
                <div className={'prefix'}>{t('poweredBy')}</div>
                <LxIcon className={'svg'}/>
            </div>
        </div>)
}

const ResetPasswordCredFull = props => {
    const [isPasswordEntered, setIsPasswordEntered] = useState(false);
    return (<div>
        {!isPasswordEntered && <ResetPasswordNewCred setIsPasswordEntered={setIsPasswordEntered}/>}
        {isPasswordEntered && <ResetPasswordFinish/>}
    </div>)
}

export default ResetPasswordCredFull;
