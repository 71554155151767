import './ActiveWatchesTileStatic.scss'
import PropTypes from "prop-types";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {ReactComponent as Watch} from './lifeguard1.svg'
import {mockedDataWatchesConnected} from "../../utils/utils";
const filter= (e) => e.is_watch_on_body && e.lifeguard_name
const ActiveWatchesTileStatic = props => {
    const [watches, setWatches] = useState(props.testMode ? mockedDataWatchesConnected.filter(filter) : props.activeWatches?.filter(filter) || [])
    useEffect(() => {
        setWatches(props.testMode ? mockedDataWatchesConnected.filter(filter) : props.activeWatches?.filter(filter) || [])
    }, [props.activeWatches, props.testMode]);
    const {t} = useTranslation();
    return <div id={'active-watches-tile-static'}>
        <div className={'title'}>{t('Active Lifeguards')}</div>
        <div className={'container'}>
            {watches?.length ? watches.map(e => <div key={Math.random() + e.id} className={'watch-item'}>
                <Watch/>
                <div className={'title color-blue'}>{e.lifeguard_name}</div>
            </div>) : <div className={'empty-title'}>No Lifeguard</div>}
        </div>
    </div>
}
ActiveWatchesTileStatic.propTypes = {
    activeWatches: PropTypes.array.isRequired,

}
export default ActiveWatchesTileStatic