import PropTypes from "prop-types";
import './LoadingSpinner.scss'
const LoadingSpinner = props => {
    return <div id={'loading-spinner'} style={{top:props.top || 0,left:props.left || 0,right:props.right || 0 }}>
        <div id="preloader">
            <div id="loader" style={{width: props.size+'px' || '150px', height:props.size+'px'  || '150px'}}></div>
        </div>
    </div>
}
LoadingSpinner.propTypes = {
    size: PropTypes.number,
    top: PropTypes.number,
    left: PropTypes.number,
    right: PropTypes.number,
}
export default LoadingSpinner