import './SafetyEventsTile.scss'
import PropTypes from "prop-types";
import {ReactComponent as DownArrow} from "./down-arrow.svg";
import {ReactComponent as UpArrow} from "./up-arrow.svg";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {useState, useMemo} from "react";
import {useTranslation} from "react-i18next";
import OptionsMenu from "../OptionsMenu/OptionsMenu";
import {ShelfTypes} from "../Dashboard/ShelfEnum";
import {dateEuFormat, getStartEndDates, hourFormat, reportHeaders, getNodeContext} from "../../utils/utils";
import moment from "moment";
import { fetchSiteSafetyEventsTableData} from "../../api/api";
const SafetyEventsTile = props => {
    const [data] = useState(props.data || {warning: 0})
    const [isDrillDownActive] = useState(props.isDrillDownActive === undefined?true:props.isDrillDownActive)
    const [isVertical] = useState(props.isVertical === undefined?false:props.isVertical)
    const [allPoolsView] = useState(!!props.data && props.data.id === -1)
    const [t] = useTranslation()
    const [showTooltip, setShowTooltip] = useState(null)
    const MAX_TOOLTIP_ROW_LENGTH = 19
    const total = (data !== undefined ? data.warning : 0) + (data !== undefined ? data.attention : 0) + (data !== undefined ? data.overcrowding : 0)
    const radius = isVertical ? 80 : 70;
    const perimeterFull = Math.PI * radius * 2
    const delimiter = 15
    const [openMenu,setOpenMenu] = useState(null)
    const countOfNonZeroValues = (data !== undefined && !!data.warning ? 1 : 0) + (data !== undefined && !!data.attention ? 1 : 0) + (data !== undefined && !!data.overcrowding ? 1 : 0)
    const semiWarningsLine = data.warning > 0 && (perimeterFull - delimiter * countOfNonZeroValues) * data.warning / total
    const semiAttentionsLine = data.attention > 0 && (perimeterFull - delimiter * countOfNonZeroValues) * data.attention / total
    const semiOvercrowdingLine = data.overcrowding > 0 && (perimeterFull - delimiter * countOfNonZeroValues) * data.overcrowding / total
    const warningsLine = ((semiWarningsLine) < 0 ? semiWarningsLine  :  semiWarningsLine);
    const attentionsLine = ((semiAttentionsLine) < 0 ? semiAttentionsLine  : semiAttentionsLine);
    const overcrowdingLine = ((semiOvercrowdingLine) < 0 ? semiOvercrowdingLine : semiOvercrowdingLine);
    const context = useMemo(getNodeContext, [])


    const handleMouseOver = (ev,type) => {
        allPoolsView && setShowTooltip([ev.nativeEvent.offsetX-180, ev.nativeEvent.offsetY-90,type])
    }

    const handleCloseToolTip = () => {
        setShowTooltip(null)
    }
    const handleMenuClick = (e)=>{
        setOpenMenu(e.currentTarget)
    }
    const handleSelection = async (selection) => {
        if (selection === 0) {
            props.openShelf(true, props.poolName, ShelfTypes.SafetyEvents, props.poolId)
        } else if (selection === 1) {
            const headers = reportHeaders.safetyEvents
            const {start, end} = getStartEndDates(props.timeFrame)
            const {data} = await fetchSiteSafetyEventsTableData(start, end, context.siteId, allPoolsView ? -1 : props.data.id)
            data.sort((a,b)=>{return moment(b.created) - moment(a.created) })
            const csvContent = "data:text/csv;charset=utf-8," + headers.join(',')+'\n'+data.map(row =>{
                return [row.pool_name,moment(row.created).format(dateEuFormat),moment(row.created).format(hourFormat), t(row.description.toLowerCase())].join(',')
            }).join('\n')
            const encodedUri = encodeURI(csvContent);
            const link = document.createElement("a");
            link.setAttribute("href", encodedUri);
            link.setAttribute("download", props.poolName + ' ' +t('safetyEvents')+".csv");
            document.body.appendChild(link); // Required for FF
            link.click();
        }
        closeMenu()
    }
    const closeMenu = ()=>{
            setOpenMenu(null)
    }
    return <div id={'safety-event-tile'} style={isVertical ? {} : {}}>

        {isDrillDownActive && <MoreVertIcon className={'more-action'}  onClick={handleMenuClick}/>}
        {!isVertical && <div className={'title'}>{t('safetyEvents')}</div>}
        <div className={'container'} style={isVertical ? {display:"flex", flexDirection: "column"} : {}}>
            <OptionsMenu closeMenu={closeMenu} anchorEl={openMenu} onSelected={handleSelection} />
            <div className={'left-inner-pane'}>
                <div className={'major-title-container'}>
                    <div test-id={'events-count'} className={'major-title'}>{isNaN(total) ? 0 : total}</div>
                    <div className={'sub-major-title'}>{t('events')}</div>
                </div>
                {allPoolsView && showTooltip &&
                    <div className={'tooltip'} style={{top: showTooltip[1], left: showTooltip[0]}}>
                       <div className={'tooltip-title-container'}>
                            <div className={`bullet m-top1 wh-10 ${showTooltip[2]==='warning'?'navy-blue':showTooltip[2]==='attention'?'blue':'light-blue'}`}></div>
                            <div className={'tooltip-title'}>{t(showTooltip[2].toString())}</div>
                       </div>
                        {data && data.toolTipData[showTooltip[2]].map(e=><div key={Math.random()+'-'+e.name} className={'pool-row'}>
                            <div key={Math.random()+e.name} className={'pool-name'}>{e.name.length > MAX_TOOLTIP_ROW_LENGTH?e.name.substring(0,MAX_TOOLTIP_ROW_LENGTH)+'..':e.name}</div>
                            <div key={Math.random()+e.name} className={'pool-value'}>{e.value}</div>

                        </div>)}
                    </div>}
                <svg id={'svg-area-safety'} className={'circle-chart'}>
                    <g>
                        <g strokeLinecap="round">
                            <svg fill="none" width="200" height="200">
                                <circle className="background" fill="none" cx="100" cy="100" stroke="#F9F7F4"
                                        strokeWidth="17" r={radius}></circle>
                                <circle className="background" fill="none" cx="100" cy="100" stroke="#EBEBEA"
                                        strokeWidth="1" r={radius + 8}></circle>
                                <circle className="background" fill="none" cx="100" cy="100" stroke="#EBEBEA"
                                        strokeWidth="1" r={radius - 8}></circle>

                                {warningsLine &&
                                    <circle onMouseOver={(e) => handleMouseOver(e, 'warning')}
                                            onMouseLeave={handleCloseToolTip}
                                            className="percentage" fill="none" cx="100" cy="100" stroke="#1F628F"
                                            strokeWidth="8" r={radius} strokeDasharray={[warningsLine, perimeterFull]}
                                            strokeDashoffset={0}></circle>}
                                {attentionsLine &&
                                    <circle onMouseOver={(e) => handleMouseOver(e, 'attention')}
                                            onMouseLeave={handleCloseToolTip}
                                            className="percentage" fill="none" cx="100" cy="100" stroke="#57A6DB"
                                            strokeWidth="8" r={radius} strokeDasharray={[attentionsLine, perimeterFull]}
                                            strokeDashoffset={-warningsLine - delimiter}></circle>}
                                {overcrowdingLine &&
                                    <circle onMouseOver={(e) => handleMouseOver(e, 'overcrowding')}
                                            onMouseLeave={handleCloseToolTip}
                                            className="percentage" fill="none" cx="100" cy="100" stroke="#C4E0F2"
                                            strokeWidth="8" r={radius}
                                            strokeDasharray={[overcrowdingLine, perimeterFull]}
                                            strokeDashoffset={-warningsLine - attentionsLine - delimiter * (countOfNonZeroValues - 1)}></circle>}
                            </svg>
                        </g>
                    </g>
                </svg>
                {!isVertical && <div className={'bottom-container'}>
                    {data.delta < 0 && <UpArrow className={'arrow-icon '}/>}
                    {data.delta > 0 && <DownArrow className={'arrow-icon'}/>}
                    {data.delta < 0 &&
                        <div className={'safety-bottom-label'}>{`${Math.abs(data.delta)}% ${t('lessThanLastPeriod')}`}</div>}
                    {data.delta > 0 &&
                        <div className={'safety-bottom-label'}>{`${Math.abs(data.delta)}% ${t('moreThanLastPeriod')}`}</div>}
                    {data.delta === 0 &&
                        <div className={'safety-bottom-label'}>{`${Math.abs(data.delta)}%  ${t('sameAsLastPeriod')}`}</div>}
                </div>}
            </div>
            <div className={'right-inner-pane'}>
                <div className={'inner-container'}>
                    <div className={'item-row'}>
                        <div className={'group1'}>
                            <div className={'bullet m-top8 navy-blue'}/>
                            <div test-id={'warning-title'} className={'item-title'}>{t('warning')}</div>

                        </div>
                        <div test-id={'warning-count'} className={'item-value'}>{data && !isNaN(data.warning) ? data.warning : 0}</div>
                    </div>
                    <div className={'item-row'}>
                        <div className={'group1'}>
                            <div className={'bullet m-top8 blue'}/>
                            <div test-id={'attention-title'}  className={'item-title'}>{t('attention')}</div>

                        </div>
                        <div test-id={'attention-count'} className={'item-value'}>{data && !isNaN(data.attention) ? data.attention : 0}</div>
                    </div>
                    <div className={'item-row'}>
                        <div className={'group1'}>
                            <div className={'bullet m-top8 light-blue'}/>
                            <div test-id={'overcrowding-title'} className={'item-title'}>{t('overcrowding')}</div>

                        </div>
                        <div test-id={'overcrowding-count'} className={'item-value'}>{data && !isNaN(data.overcrowding) ? data.overcrowding : 0}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}
SafetyEventsTile.propTypes = {
    data: PropTypes.shape({
        delta: PropTypes.number.isRequired,
        warning: PropTypes.number.isRequired,
        allPoolsView: PropTypes.bool,
        attention: PropTypes.number.isRequired,
        overcrowding: PropTypes.number.isRequired
    }),
    timeFrame:PropTypes.object.isRequired,
    isDrillDownActive: PropTypes.bool,
    allPoolsData: PropTypes.array,
    isVertical: PropTypes.bool
}
export default SafetyEventsTile