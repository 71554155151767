import {TableCell, TableHead, TableRow, TableSortLabel} from "@mui/material";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import ReportsSortLabel from "./ReportsSortLabel";
import './ReportsTableHead.scss'


const ReportsTableHead = props => {

    const [t] = useTranslation();
    const {order, orderBy, onRequestSort, isSinglePoolView} = props;
    const headCells = [
        {
            id: 'created',
            label: 'date'
        },
        {
            id: 'created',
            label: 'time'
        },
        {
            id: 'description',
            label: 'description'
        }
    ]
    if(!isSinglePoolView)
        headCells.unshift({
            id: 'pool_name',
            label: 'pool'
        })
    const createSortHandler = (property) => (event, direction) => {
        onRequestSort(event, property, direction);
    };

    return (
        <TableHead id={'reports-table-head'}>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={'left'}
                        padding={'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        className={'cell-title'}
                    >
                        <ReportsSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                            label={t(headCell.label)}
                        />
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

ReportsTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    isSinglePoolView: PropTypes.bool.isRequired
};
export default ReportsTableHead;