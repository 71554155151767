import './ActiveWatchesTileGeneral.scss'
import {ReactComponent as TotalWatchesIcon} from "./totalWatchesIcon.svg";
import WatchRoundedIcon from '@mui/icons-material/WatchRounded';

import PropTypes from "prop-types";
import {useState} from "react";
import {useTranslation} from "react-i18next";


const ActiveWatchesTileGeneral = props=>{
    const [value] = useState(props.value)
    const [t] = useTranslation()
    return <div id={'active-watches-tile-general'}>
        <div className={'value-title-container'}>
            <div className={'value-title'}>{value}</div>
            <div className={'bottom-title'}>{t('activeWatches')}</div>
        </div>
        <div className={'icon'}>
            <WatchRoundedIcon className={'icon-bg'}/>

        </div>
    </div>
}
ActiveWatchesTileGeneral.propTypes = {
    value: PropTypes.number.isRequired
}

export default ActiveWatchesTileGeneral