import './PoolGeneralTile.scss'
import PropTypes from "prop-types";
import RiskLevelTile from "../RiskLevelTile/RiskLevelTile";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {NotificationsOffOutlined} from "@mui/icons-material";

const PoolGeneralTile = props => {
    const [riskValue] = useState(props.riskValue)
    const [poolName] = useState(props.poolName)
    const [swimmersCount] = useState(props.swimmersCount)
    const [t] = useTranslation()
    useEffect(() => {
    }, [props.isAlertsTemporaryDisabled]);
    return <div id={'pool-general-tile'} className={props.isAtEdge ? 'at-edge' : ''}>
        <RiskLevelTile noShadow={true} riskValue={riskValue} title={poolName}/>

        <div className={'vertical-separator'}/>
        <div className={'right-label-container'}>
            <div className={'general-label-container '}>
                {props.isAlertsTemporaryDisabled &&
                    <div className={'alerts-temp-disabled-icon'}>
                        <NotificationsOffOutlined style={{color: 'rgba(0, 0, 0, 0.94)', width: '50px', height: '50px'}}/>
                    </div>
                }
                <div className={'risk-main-title'}>{swimmersCount}</div>
                <div className={'risk-sub-title'}>{t('swimmers')}</div>
            </div>
        </div>

    </div>
}
PoolGeneralTile.propTypes = {
    poolName: PropTypes.string.isRequired,
    swimmersCount: PropTypes.number.isRequired,
    riskValue: PropTypes.number.isRequired,
    isAtEdge: PropTypes.bool,
    isAlertsTemporaryDisabled: PropTypes.bool
}
export default PoolGeneralTile