import './SimpleDatePicker.scss'
import PropTypes from "prop-types";
import React, {useState} from "react";
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import moment from 'moment'
import "rc-time-picker/assets/index.css";
import {DateRange, defaultInputRanges, defaultStaticRanges} from 'react-date-range';
import useComponentVisible from "../../utils/CustomHook";
import * as locales from 'react-date-range/dist/locale';
import {useTranslation} from "react-i18next";
import {DefinedRange} from 'react-date-range';
import {languageToDateRangeFormat} from "../../utils/utils";

const uiDisplayFormat = 'DD-MMM-YYYY'

const pallet = ['#52A1CA']

const SimpleDatePicker = props => {
    const [t, tObj] = useTranslation()
    const staticRangesLabels = {
        "Today": t('today'),
        "Yesterday": t('yesterday'),
        "This Week": t('thisWeek'),
        "Last Week": t('lastWeek'),
        "This Month": t('thisMonth'),
        "Last Month": t('lastMonth')
    };

    const inputRangesLabels = {
        "days up to today": t('daysUpTo')
    };
    function translateRange(dictionary) {
        return (item) =>
            dictionary[item.label] ? { ...item, label: dictionary[item.label] } : item;
    }
    const translatedStaticRanges = defaultStaticRanges.map(translateRange(staticRangesLabels));

    const translatedInputRanges = defaultInputRanges.map(translateRange(inputRangesLabels));
    const {ref, isComponentVisible, setIsComponentVisible} = useComponentVisible(false);
    const [startTime] = useState(null)
    const [endTime] = useState(null)
    const [currentDate, setCurrentDate] = useState([
        {
            startDate: props.timeFrame.start.toDate(),
            endDate: props.timeFrame.end.toDate(),
            key: 'selection'
        }
    ]);
    const toggleMenu = () => {
        setIsComponentVisible((!isComponentVisible))
    }
    const handleMenuClose = () => {
        setIsComponentVisible(false)
    }
    const handleChangeDateRange = (ev) => {
        setCurrentDate([ev.selection])
    }
    const cancelSelectedDateAndTime = () => {
        setCurrentDate([{
            startDate: props.timeFrame.start.toDate(),
            endDate: props.timeFrame.end.toDate(),
            key: 'selection'
        }]);
        handleMenuClose();
    }
    const submitSelectedDateAndTime = () => {
        const data = currentDate[0]
        if (startTime && endTime) {
            props.setTimeFrame({
                start: moment(data.startDate).hour(startTime.hour()).minute(startTime.minute()),
                end: moment(data.endDate).hour(endTime.hour()).minutes(endTime.minute())
            })
        } else if (startTime) {
            props.setTimeFrame({
                start: moment(data.startDate).hour(startTime.hour()).minute(startTime.minute()),
                end: moment(data.endDate.endOf('day'))
            })
        } else if (endTime) {
            props.setTimeFrame({
                start: moment(data.startDate).startOf('day'),
                end: moment(data.endDate.hour(moment().hour()).minutes(moment().minute()))
            })
        } else if (!startTime && !endTime) {
            props.setTimeFrame({start: moment(data.startDate).startOf('day'), end: moment(data.endDate).endOf('day')})
        }
        handleMenuClose();
    }
    return <div id={'date-picker'} className={'chip'} title="Select Time Range">
        <CalendarTodayOutlinedIcon test-id={'date-selector-icon'} onClick={toggleMenu} className={'date-selector-icon'}/>
        <div className={'calendar-title'} onClick={toggleMenu}>
            <div className={'date-value start'}>{moment(currentDate[0].startDate).format(uiDisplayFormat)}</div>
            <ArrowRightAltIcon className={'date-icon-arrow'}/>
            <div className={'date-value end'}>{moment(currentDate[0].endDate).format(uiDisplayFormat)} </div>
        </div>
        {isComponentVisible && <div className={'calendar-tile'} ref={ref}>
            <DateRange className={'calendar'}
                       onChange={handleChangeDateRange}
                       rangeColors={pallet}
                       showSelectionPreview={true}
                       moveRangeOnFirstSelection={false}
                       months={2}
                       fixedHeight
                       ranges={currentDate}
                       maxDate={new Date()}
                       direction="horizontal"
                       locale={locales[languageToDateRangeFormat[tObj.language]]}
            />
            <div className={'bottom-menu'}>
                <DefinedRange
                    staticRanges={translatedStaticRanges}
                    inputRanges={translatedInputRanges}
                    className={'range'}
                    onChange={handleChangeDateRange}
                    rangeColors={pallet}
                    ranges={currentDate}

                />
                <div className={'bottom-container'}>
                    <div className={'button cancel'} onClick={cancelSelectedDateAndTime}>{t('cancel')}</div>
                    <div className={'button submit'} onClick={submitSelectedDateAndTime}>{t('setDate')}</div>
                </div>
            </div>
            <div className={'margin-holder'}/>
        </div>
        }
    </div>
}
SimpleDatePicker.propTypes = {
    timeFrame: PropTypes.object.isRequired,
    setTimeFrame: PropTypes.func.isRequired
}
export default SimpleDatePicker