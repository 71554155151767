import './MonitorPage.scss'
import React, {useEffect, useState} from "react";
import {getNodeContext} from "../../utils/utils";
import mixpanel from "mixpanel-browser";
import {fetchSiteLiveData, fetchSiteSwimmerHistory} from "../../api/api";
import moment from "moment";
import PoolDiagramTileStatic from "../PoolDiagramTileStatic/PoolDiagramTileStatic";
import SwimmersCountHistoryTileStatic from "../SwimmersCountHistoryTileStatic/SwimmersCountHistoryTileStatic";
import ActiveWatchesTileStatic from "../ActiveWatchesStaticTile/ActiveWatchesTileStatic";
import HeaderStatic from "../HeaderStatic/HeaderStatic";

mixpanel.init(process.env.REACT_APP_MIXPANEL_KEY, {debug: true, track_pageview: true, persistence: 'localStorage'});

const MonitorPage = () => {
    const testMode= false
    const [selectedPool, setSelectedPool] = useState(1)
    const [siteData, setSiteData] = useState([])
    const [swimmersData, setSwimmersData] = useState([])
    const start = moment().startOf('day').add(3, 'hours').toISOString()
    const end = moment().toISOString()
    const getData = () => {
        const context = getNodeContext()
        const siteId = context.siteId
        const start = moment().startOf('day').add({"hour": 2.9}).toISOString()
        const end = moment().toISOString()
        Promise.all([fetchSiteLiveData(siteId), fetchSiteSwimmerHistory(siteId, start, end)])
            .then(([_siteData, _swimmersHistory]) => {
                if (!_siteData) {
                    return
                }
                setSiteData(_siteData)
                setSwimmersData(_swimmersHistory)
            }).catch(e => {
            console.log("Error fetching data  => e", e)
        })

    }
    useEffect(() => {

        getData()
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const poolSelected = Number(urlParams.get('pool')) || 1
        setSelectedPool(poolSelected)
        mixpanel.track_pageview({"page": "Monitor"});
        const interval1 = setInterval(getData, 5 * 1000)
        const interval2= setInterval(() => {
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            const poolSelected = Number(urlParams.get('pool')) || 1
            if (poolSelected < 3) {
                urlParams.set('pool', poolSelected + 1)
            } else {
                urlParams.set('pool', 1)
            }
            window.location.search = urlParams.toString()
        }, 20*1000)
        return () => {
            clearInterval(interval1)
            clearInterval(interval2)
        }
    }, [])

    return <div id={'monitor-page'}>
        <HeaderStatic poolName={siteData[selectedPool]?.dashboard_name || ''}  context={getNodeContext()}/>
        <div className={'main-pane'}>
            <div className={'lx-logo-container-monitor'}>
                <img src={'logo-03.png'} className={'lx-logo'} alt={'Logo'}/>
            </div>
            <div className={'right-pane'}>
                <div className={'pane'}>
                    <PoolDiagramTileStatic
                                     testMode={testMode}
                                     height={'300px'}
                                     titleSize={'1.5em'}
                                     swimmerDotColor={'#57A6DB'}
                                     key={Math.random() + "-key"}
                                     locations={siteData[selectedPool]?.content?.locations || []}
                                     transformation={siteData[selectedPool]?.content?.transformation || []}
                                     poolId={siteData[selectedPool]?.id}
                                     capacity={siteData[selectedPool]?.content?.capacity || 1}
                                     coordinates={siteData[selectedPool]?.coordinates}/>

                <ActiveWatchesTileStatic activeWatches={siteData[selectedPool]?.content.activeWatches} poolId={1} testMode={testMode}/>
                </div>
                <div className={'pane'}>
                    <SwimmersCountHistoryTileStatic data={swimmersData[selectedPool] || {data:[]}} testMode={testMode} timeFrame={{start, end}}/>
                </div>
            </div>
        </div>
    </div>
}
export default MonitorPage