import './LoginPage.scss'
import React, {useState} from "react";
import Snackbar from "@mui/material/Snackbar";
import PropTypes from "prop-types";
import LanguageSelector from "../LanguageSelector/LanguageSelector";
import {useTranslation} from "react-i18next";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import {Box, Tab, IconButton} from "@mui/material";
import MFABox from "./MFABox/MFABox";
import {senAuthenticationEmail} from "../../api/api";

const LoginPage = props => {
    const [email, setEmail] = useState()
    const [username, setUsername] = useState()
    const [password, setPassword] = useState()
    const [completedFlow, setCompletedFlow] = useState(false)
    const [loginType, setLoginType] = useState('username')
    const [t] = useTranslation()
    const [errorMessage, setErrorMessage] = useState('')
    const [errorState, setErrorState] = useState(false)
    const [startMFATransition, setStartMFATransition] = useState(false)
    const [showMFAContent,setShowMFAContent] = useState(false)
    const [MFAToken, setMFAToken] = useState('')
    const [MFAMethod, setMFAMethod] = useState('')
    const handleWrongInput = () => {
        setErrorMessage("Username/Password not provided")
        setErrorState(true)
    }
    const handleSubmit = async () => {
        try {
            if (!username || !password) {
                return handleWrongInput()
            }
            await props.submit(username, password)
            window.location.assign(props.redirectPath)
        } catch (ex) {
            if(ex?.token){
                setMFAToken(ex.token)
                setMFAMethod(ex.method)
                setStartMFATransition(true)
                setTimeout(()=>{setShowMFAContent(true)},300)
                return
            }
            if (ex.response?.status === 423){
                setErrorMessage(t('locked'))
                setErrorState(true)
            }else  if (ex.response?.status === 401){
                setErrorMessage('Error logging in')
                setErrorState(true)
            }else  if (ex.response?.status === 429){
                setErrorMessage(t('tooManyAttempts'))
                setErrorState(true)
            }else {
                setErrorMessage('Error in our system')
                setErrorState(true)
            }
        }
    }
    const handleUsername = (e) => {
        setUsername(e.target.value.trim())
    }
    const handlePassword = (e) => {
        setPassword(e.target.value.trim())
    }

    const handleClose = () => {
        setErrorState(false)
    }
    function handleUsernameInputEnterClick(event) {
        if(showMFAContent){
            return
        }
        if (event.key === 'Enter') {
            setUsername(event.target.value.trim())
            if (password && username){
                handleSubmit().then(r => {
                })
            }else{
                setErrorState(true)
                setErrorMessage("Username and password must be provided")
            }
        }
    }
    function handlePasswordInputEnterClick(event) {
        if(showMFAContent){
            return
        }
        if (event.key === 'Enter') {
            setPassword(event.target.value.trim())
            if (password && username){
                handleSubmit().then(r => {
                })
            }else{
                setErrorState(true)
                setErrorMessage("Username and password must be provided")
            }
        }
    }

    function handleLoginType(event, val) {
        setLoginType(val)
    }

    function validateEmail(email) {
        if (!email ||email.length <= 6){
            return false
        }
        const valid = email.indexOf('@')>1
            && email.lastIndexOf('.')>=1
            && email.indexOf('#') ===-1
            && email.indexOf('"') ===-1
            && email.indexOf("'") ===-1
            && email.indexOf("/") ===-1
            && email.indexOf("=") ===-1
            && email.indexOf("\\") ===-1
            && email.indexOf("eval(") ===-1
            && email.indexOf("(") ===-1
            && email.indexOf(")") ===-1
        return valid;
    }

    async function handleSubmitEmailRequest() {
        if(completedFlow) return
        if ( validateEmail(email)){
            try{
                await senAuthenticationEmail(email)
                setCompletedFlow(true)
            }
            catch (ex){
                if(ex?.response?.status){
                    switch (ex.response.status) {
                        case 423:
                            setErrorMessage('Email based authentication is not enabled for your organization')
                            setErrorState(true)
                            break;
                        default:
                            break;
                    }
                }
            }

        }else {
            setErrorState(true)
            setErrorMessage('A valid email must be provided')
        }
    }

    function handleEmailInput(e) {
        setEmail(e.target.value)
    }


    return <div id={'login-page'}>
        <Snackbar
            open={errorState}
            autoHideDuration={5000}
            onClose={handleClose}
            message={errorMessage}
            ContentProps={{sx: {backgroundColor: '#ed7847'}}}
            style={{borderRadius: 5}}
            anchorOrigin={{horizontal: 'right', vertical: "top"}}
        />
        <div className={'lang-selector'}>
            <LanguageSelector/>
        </div>
        <div className={'left-pane'}>
            <div className={'lx-logo-container'}>
                <img src={'logo-03.png'} className={'lx-logo'} alt={'Logo'}/>
            </div>
        </div>
        <div className={`right-pane ${startMFATransition&&'rotate'}`}>
            <div className={`form-container ${startMFATransition&&'mfa'}`}>
                {
                    showMFAContent ?
                        <MFABox redirectPath={props.redirectPath} token={MFAToken} username={username} password={password} method={MFAMethod}/>
                        :
                        <Box sx={{width: '100%', typography: 'body1'}} className={`${startMFATransition&&'invisible'}`}>
                            <TabContext value={loginType}>
                                <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                                    <TabList onChange={handleLoginType} aria-label="lab API tabs example">
                                        <Tab sx={{color: '#1f172a', textTransform:'none',fontSize:'1.15em',backgroundColor:loginType==='email'?'#F7F8FC':'#eef5ff'}} disableRipple label={t('password')} value='username'/>
                                        <Tab sx={{color: '#1f172a',textTransform:'none',fontSize:'1.15em',backgroundColor:loginType==='email'?'#eef5ff':'#F7F8FC'}} disableRipple label={t('email')}  value='email'/>
                                    </TabList>
                                </Box>
                                <TabPanel value="username">
                                    <div className={'switch-container'}>
                                        <div className={'title'}>{t("logInWithUser")}</div>
                                    </div>
                                    <form className={'form'}>
                                        <div className={'mini-container paddingB'}>
                                            <label>{t('username')}</label>
                                            <input test-id={'input-username'} maxLength={120} onKeyDown={handleUsernameInputEnterClick} className={`input-field ${errorState ? 'errorState' : ''}`} onChange={handleUsername}
                                                   type="text"/>
                                        </div>
                                        <div className={'mini-container'}>
                                            <label>{t("password")}</label>
                                            <input test-id={'input-password'} maxLength={120} onKeyDown={handlePasswordInputEnterClick}
                                                   className={`input-field ${errorState ? 'errorState' : ''}`}
                                                   onChange={handlePassword} type="password"/>
                                        </div>
                                        <div className={'fp-container'}>
                                            <a className={'link-fp'} href="/reset_password">{t('forgotPassword')}</a>
                                        </div>
                                        <div test-id={'login-button'} onClick={handleSubmit} className={'submit-button'}>{t('logIn')}</div>
                                    </form>
                                </TabPanel>
                                <TabPanel value="email">
                                    <div className={'switch-container'}>
                                        <div className={'title'}>{t("logInWithEmail")}</div>
                                    </div>
                                    <div className={'form'}>
                                        <div className={'mini-container paddingB paddingT60'}>
                                            <label>{t('email')}</label>
                                            <input disabled={completedFlow} className={`input-field ${errorState ? 'errorState' : ''}`} onChange={handleEmailInput}
                                                   type="text"/>
                                        </div>
                                        <div onClick={handleSubmitEmailRequest} className={`submit-button ${completedFlow?'button-inactive':''}`}>{completedFlow?t('emailSent'):t('sendEmail')}</div>
                                    </div>
                                </TabPanel>
                            </TabContext>
                        </Box>
                }
            </div>
        </div>
    </div>
}
LoginPage.propTypes = {
    submit: PropTypes.func.isRequired
}
export default LoginPage