import {closeSnackbar, enqueueSnackbar} from "notistack";
import CloseIcon from '@mui/icons-material/Close';
import {IconButton} from "@mui/material";
/**
 * Credit to MUI team @ https://mui.com
 */
import * as React from 'react';
import {Transition} from 'notistack';
import {
    useForkRef,
    reflow,
    getTransitionProps,
    createTransition,
} from './sharedSnackbarTransition';

const styles = {
    entering: {
        transform: 'none',
    },
    entered: {
        transform: 'none',
    },
};

const Zoom = React.forwardRef((props, ref) => {
    const {
        children,
        in: inProp,
        style,
        timeout = 0,
        onEnter,
        onEntered,
        onExit,
        onExited,
        ...other
    } = props;

    const nodeRef = React.useRef(null);
    const handleRefIntermediary = useForkRef(children.ref, ref);
    const handleRef = useForkRef(nodeRef, handleRefIntermediary);

    const handleEnter = (node, isAppearing) => {
        reflow(node);

        const transitionProps = getTransitionProps({
            style,
            timeout,
            mode: 'enter',
        });

        node.style.webkitTransition = createTransition(
            'transform',
            transitionProps
        );
        node.style.transition = createTransition('transform', transitionProps);

        if (onEnter) {
            onEnter(node, isAppearing);
        }
    };

    const handleExit = (node) => {
        const transitionProps = getTransitionProps({
            style,
            timeout,
            mode: 'exit',
        });

        node.style.webkitTransition = createTransition(
            'transform',
            transitionProps
        );
        node.style.transition = createTransition('transform', transitionProps);

        if (onExit) {
            onExit(node);
        }
    };

    return (
        <Transition
            appear
            in={inProp}
            nodeRef={nodeRef}
            onEnter={handleEnter}
            onEntered={onEntered}
            onExit={handleExit}
            onExited={onExited}
            timeout={timeout}
            {...other}
        >
            {(state, childProps) =>
                React.cloneElement(children, {
                    style: {
                        transform: 'scale(0)',
                        visibility: state === 'exited' && !inProp ? 'hidden' : undefined,
                        ...styles[state],
                        ...style,
                        ...children.props.style,
                    },
                    ref: handleRef,
                    ...childProps,
                })
            }
        </Transition>
    );
});

Zoom.displayName = 'Zoom';


const action = snackbarId => (
    <>
        <IconButton onClick={() => {
            closeSnackbar(snackbarId)
        }}>
            <CloseIcon/>
        </IconButton>
    </>
);

const getSnackbarProps = (variant, timeout) => {
    return {
        TransitionComponent: Zoom,
        action,
        variant,
        anchorOrigin: {vertical: "top", horizontal: "right"},
        style: {
            fontWeight: 500,
            marginTop: "-20px",
            height: "50px",
            marginRight: "-20px",
            width: "42.65vw",
            padding: "10px",
            backgroundColor: "#001333",
            borderRadius: '4px',
            border: '1px #3D5484 solid',
            color: variant === "error" ? "#FB7743" : "#00B389"
        },
        autoHideDuration: timeout
    }
}

/**
 *Show snack bar.
 * @param {string} message
 * @param {string} variant "success" or "error"
 */
export const showSnackbar = (message, variant, timeout = 5000) => {
    enqueueSnackbar(message, getSnackbarProps(variant, timeout))
}
