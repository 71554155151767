import './RiskLevelTile.scss'
import {useEffect, useState} from "react";
import React from 'react'
import PropTypes from "prop-types";
import Arrow from "./arrow.png";
import {useTranslation} from "react-i18next";
import {NotificationsOffOutlined} from "@mui/icons-material";
const RiskLevelTile = props => {
    const [riskValue] = useState(  props.riskValue)
    const [isAlertsTemporaryDisabled] = useState(props.isAlertsTemporaryDisabled)
    const [title] = useState(props.title)
    const [noShadow] = useState(props.noShadow)
    const [t] = useTranslation();
    const [tooltipAnchor, setTooltipAnchor] = useState(null)
    const radius = noShadow ? 75 : 80
    const perimeterFull = Math.PI * radius * 2
    const arrowAngle = riskValue / 100 * 180
    const riskLevelCircle = riskValue ? (riskValue / 100) * (perimeterFull / 2) + perimeterFull / 2 : 0
    const barCx = 100
    const barCy = 100

    const riskLabelObj = riskValue <= 33 ? {
        title: 'lowRisk',
        color: '#57A6DB'
    } : riskValue <= 66 ? {title: 'mediumRisk', color: '#ED7847'} : {title: 'highRisk', color: '#D02E26'}
    const showInfoTooltip = (event) => {
        setTooltipAnchor({x: event.nativeEvent.offsetX + 25, y: event.nativeEvent.offsetY + 25})
        setTimeout(closeTooltip, 300)
    }
    const closeTooltip = () => {
        setTooltipAnchor(null)
    }
    let iconStyle = {
        color: 'rgba(0, 0, 0, 0.94)',
        width:'50px',
        height:'50px'
    };
    return (
        <div id='risk-level-tile'
             style={noShadow ? {height: 210, width: 180} : {boxShadow: '3px 2px 8px 1px rgba(100, 100, 100, 0.2)'}}
             key={'key-' + title + Math.random()}>
            {tooltipAnchor && <div className={'tooltip-tile'} style={{
                top: `${tooltipAnchor.y}px`,
                left: `${tooltipAnchor.x}px`
            }}>{t('riskLevelExplanation')}</div>}
            {props.isAlertsTemporaryDisabled  &&
                <div className={'alerts-temp-disabled-icon'}>
                    <NotificationsOffOutlined style={iconStyle}/>
                </div>}
            <div className={'info-hover-area'}>
                <div className={'pane'} onMouseOut={closeTooltip} onMouseOver={showInfoTooltip}/>
            </div>
            <div className={'title'}>{title || t('riskLevel')}</div>
            <div className={'graph-area'}>
                <img className={noShadow ? 'arrow-small' : 'arrow-big'} src={Arrow} style={{
                    transition: 'all 200ms cubic-bezier(0.42, 0, 0.58, 1) 10ms',
                    transform: `rotateZ(${arrowAngle}deg) translateX(-27px) translateY(0px)`
                }} alt={"risk-arrow"}/>
                <div className={'graph-bar'}>
                    <svg className={'techometer'} fill="none" width="200" height="100">
                        <defs>
                            <linearGradient id="gradient" x1="0%" y1="0%" x2="110%" y2="0%">
                                <stop offset="5%" stopColor="#57a6db" />
                                <stop offset="40%" stopColor="#ED7847" />
                                <stop offset="100%" stopColor="#D02E26" />
                            </linearGradient>
                        </defs>
                        <circle className="background" fill="none" cx={barCx} cy={barCy} stroke="#F9F7F4"
                                strokeWidth="17" r={radius}></circle>
                        <circle className="background" fill="none" cx={barCx} cy={barCy} stroke="#EBEBEA"
                                strokeWidth="1" r={radius + 8}></circle>
                        <circle className="background" fill="none" cx={barCx} cy={barCy} stroke="#EBEBEA"
                                strokeWidth="1" r={radius - 8}></circle>
                        <circle className="techometer" fill="none" cx={barCx} cy={barCy} stroke="url(#gradient)"
                                strokeWidth="8" r={radius} strokeDasharray={[riskLevelCircle, perimeterFull]}
                                strokeDashoffset="-1"></circle>
                    </svg>
                </div>
            </div>
            <div className={'value-title-container'}>
                <div className={'value-title'} style={{color: riskLabelObj.color}}>{t(riskLabelObj.title)}</div>
            </div>
        </div>)
}
RiskLevelTile.propTypes = {
    noShadow: PropTypes.bool,
    title: PropTypes.string,
    isAlertsTemporaryDisabled: PropTypes.bool,
    riskValue: (props, propName, componentName) => (props[propName] < 0 || props[propName] > 100) && new Error('Invalid value provided, risk should 0-100 ')
}
export default RiskLevelTile