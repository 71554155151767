import './Header.scss'
import LanguageSelector from '../LanguageSelector/LanguageSelector';
import Notifications from '../Notifications/Notifications';
import DateAndTime from '../DateAndTime/DateAndTime';
import UserTitle from '../UserTitle/UserTitle';
import UserMenu from '../UserMenu/UserMenu'
import PropTypes from 'prop-types';
import {envMap} from "../../utils/utils";

const baseLogoUrl = process.env.REACT_APP_IMAGE_STORAGE_PATH + 'icons/' + envMap[process.env.NODE_ENV] + '/'

const Header = props => {
    let isStaff
    let isAnonymous
    let username
    const context = props.context
    isStaff = context.isStaff
    isAnonymous = context.isAnonymous
    username = context.username

    function handleImageError() {
        const img = document.getElementById('company-logo')
        img.src = baseLogoUrl + `icon_org_id_lx.svg`
    }

    const iconUrl = `${baseLogoUrl}${isAnonymous ? 'icon_org_id_lx.svg' : `icon_org_id_${props.context.orgId}.svg`}`
    return <div id={'main-header'}>
        <img id={'company-logo'} className={'company-icon'} src={iconUrl}
             onError={handleImageError} alt={'Company-icon'}/>
        <div className={'vertical-separator left'}/>
        <UserMenu styles={{top:5,right:10}} adminMode={props.adminMode} setAdminMode={props.setAdminMode} username={username}/>
        <div className={'vertical-separator right'} style={{paddingRight:'14px'}}/>
        <LanguageSelector/>
        <div className={'vertical-separator right'}/>
        <Notifications/>
        <div className={'vertical-separator right'}/>
        <DateAndTime timezone={context.timezone}/>
        <UserTitle username={props.context.username} orgName={props.context.orgName}
              siteName={isStaff ? "Testing " + props.context.siteTitle : isAnonymous? "Demo Site": props.context.siteTitle}/>
    </div>
}
Header.propTypes = {
    context: PropTypes.object.isRequired,
    setAdminMode: PropTypes.func,
    adminMode: PropTypes.bool.isRequired,
}
export default Header