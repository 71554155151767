import './ActiveWatchesTile.scss'
import PropTypes from "prop-types";
import { useState} from "react";
import {useTranslation} from "react-i18next";
import WatchOutlinedIcon from '@mui/icons-material/WatchOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {ShelfTypes} from "../Dashboard/ShelfEnum";
const mockedData = [{"id":"0C:8D:CA:6C:90:C1"},{"id":"0C:8D:CA:6C:90:A1"},{"id":"0C:8D:CA:6C:90:A2"},{"id":"0C:8D:CA:6C:90:A3"},]
const ActiveWatchesTile = props => {
    const [watches] = useState( props.testState ? mockedData:props.activeWatches || [])
    const [isDrillDownActive] = useState(props.isDrillDownActive === undefined?true:props.isDrillDownActive)
    const {t} = useTranslation();
    return <div id={'active-watches-tile'}>
        {isDrillDownActive && <MoreVertIcon className={'more-action'} onClick={() => {props.openShelf(true, props.poolName, ShelfTypes.ActiveWatches, props.poolId)}}/>}
        <div className={'title'}>{t('connectedWatches')}</div>
        <div className={'container'}>
            {watches.length? watches.map(e => <div key={Math.random() + e.id}>
                <div className={'watch-item'}>
                    <div className={'dot'}/>
                    <WatchOutlinedIcon/>
                    <div className={'watch-name'}>{e.id.substring(e.id.length - 5)}</div>
                </div>
                <div className={'horizontal-separator'}/>
            </div>):<div className={'empty-title'}>{t('noActiveWatches')}</div>}
        </div>
    </div>
}
ActiveWatchesTile.propTypes = {
    activeWatches: PropTypes.array.isRequired,
    testState: PropTypes.bool,
    isDrillDownActive: PropTypes.bool,
    poolId:PropTypes.number.isRequired

}
export default ActiveWatchesTile