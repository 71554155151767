import './TablePaginationToolbar.scss'
import {useTranslation} from "react-i18next";
import TablePaginationActions from "./TablePagination";


const TablePaginationToolbar = props => {
    const [t] = useTranslation()
    return (
        <div id={'table-pagination-toolbar'}>
            <div className={'toolbar-container'}>
                <div className={'plain-text'}>
                    {t('showing')}&nbsp;
                    {props.children.props.children[3].props.children}
                </div>
                <div className={'pagination-actions'}>
                    <TablePaginationActions count={props.children.props.children[4].props.count} onPageChange={props.children.props.children[4].props.onPageChange} page={props.children.props.children[4].props.page} rowsPerPage={props.children.props.children[4].props.rowsPerPage}/>
                </div>
            </div>
        </div>
    )
}

export default TablePaginationToolbar;