import './Notifications.scss'
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';

const Notifications = props => {
    return <div id={'notifications-center'}>
       <NotificationsNoneIcon/>
    </div>
}
export default Notifications

