import './PoolDiagramTile.scss'
import {useTranslation} from "react-i18next";
import PoolCapacityTile from "./PoolCapacityTile/PoolCapacityTile";
import PropTypes from "prop-types";
import {useEffect, useState} from "react";
import {transformLinearVector} from "../../utils/LinearCalcHelper";
import {envMap, mockDataLocations} from "../../utils/utils";
import {AdminDotTheme} from "../../utils/Themes"

const url = process.env.REACT_APP_IMAGE_STORAGE_PATH + 'pools/' + envMap[process.env.NODE_ENV] + '/'
const transformationDimensions = {height: 710, width: 1380}
const height = 200
const layoutDimensions = {height, width: height * transformationDimensions.width / transformationDimensions.height}
const adjustmentHeight = 0;
const adjustmentWidth = 0;
const PoolDiagramTile = props => {
    const [capacity] = useState(props.capacity || 0)
    const [transformation] = useState(props.transformation && props.transformation.length && props.transformation || [[],[],[]])
    const [locations] = useState(props.testMode? mockDataLocations:props.locations || [])
    const [error, setError] = useState(false)
    const {t} = useTranslation();
    const scale = {
        x: (layoutDimensions.width) / transformationDimensions.width,
        y: (layoutDimensions.height) / transformationDimensions.height
    }
    useEffect(() => {

    }, [props.locations,props.testMode]);
    function handleImageError() {
        setError(true)
        const img = document.getElementById('pool-image')
        img.src = url + `pool_id_0.svg`
    }
    const swimmersLocationsTransformed = locations.map(l => transformLinearVector(transformation, l))

    return <div id={'pool-diagram-tile'} style={{height: props.height || '100%',maxWidth:props.maxWidth||'100%',fontSize:props.titleSize || null}}>
        <div className={'pool-tile-title'}>{t('swimmersLocations')}</div>
        <div className={'inner-box-pd'}>
            <PoolCapacityTile data={{capacity, locations}}/>
            <div className={'pool-image-container'}>
                <img id={'pool-image'} className={'img-pool'} src={url + 'pool_id_' + props.poolId + '.svg'}
                     onError={error ? () => {} : handleImageError} alt="Pool-Image"/>
                <div id={'pool-canvas'} className={'swimmers-layout'}>
                    {swimmersLocationsTransformed.map((coordinate,index) => {
                        let addClass = ''
                            let top = adjustmentHeight + (scale.y * coordinate.y)
                            let left = adjustmentWidth + (scale.x * coordinate.x)
                        if ( top > layoutDimensions.height ){
                         top =  layoutDimensions.height
                            // addClass = 'green'
                        }
                       if (left > layoutDimensions.width ){
                        left = layoutDimensions.width
                       }
                       if (left <0 ){
                           left = 0
                       }
                       if (top < 0){
                           top=0
                       }
                        return <div key={Math.random() + '-key'} className={` ${props.adminMode?'admin-dot':'dot'} ${addClass}`} style={{
                                top: `${top}px`,
                                left: `${left}px`,
                                backgroundColor: props.adminMode?AdminDotTheme[index%AdminDotTheme.length]:props.swimmerDotColor || null
                            }}></div>

                        }
                    )}
                </div>
            </div>
        </div>

    </div>
}
PoolDiagramTile.propTypes = {
    capacity: (props, propName) => (props[propName] < 0 || props[propName] > 1) && new Error('Invalid value provided, capacity should be float 0-1 '),
    locations: PropTypes.array.isRequired,
    poolId: PropTypes.number.isRequired,
    transformation: PropTypes.array.isRequired,
    height: PropTypes.string,
    maxWidth: PropTypes.string
}
export default PoolDiagramTile