import './ReportsHeader.scss'
import PropTypes from 'prop-types';
import {envMap, getNodeContext} from "../../../utils/utils";
import {ButtonGroup} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useState} from "react";
import LxIcon from "../../../public/images/lx_logo_white.png";
import UserMenu from "../../UserMenu/UserMenu";

const baseLogoUrl = process.env.REACT_APP_IMAGE_STORAGE_PATH + 'icons/' + envMap[process.env.NODE_ENV] + '/'

const ReportsHeader = props => {

    const [t] = useTranslation()
    const [selectedPage, setSelectedPage] = useState(props.selectedPage)
    const context = getNodeContext()
    const username  = context.username
    const iconUrl =  context.isAnonymous?`${baseLogoUrl}icon_org_id_lx.svg`:`${baseLogoUrl}${`icon_org_id_${props.orgId}.svg`}`

    function handleImageError() {
        const img = document.getElementById('company-logo')
        img.src = baseLogoUrl + `icon_org_id_lx.svg`
    }

    function handlePageSelection(select) {
        setSelectedPage(select)
        props.setSelectedPage(select)
    }
    function redirectToDashboard(){
        window.location.href = '/dashboard';
    }

    return <div id={'reports-header'}>
        <img id={'company-logo'} className={'company-icon'} src={iconUrl}
             onError={handleImageError} alt={'Company-icon'} onClick={redirectToDashboard}/>
        <div className={'pool-selection-area'}>
            <ButtonGroup>
                {/*<div className={`pageSelectionButton ${0 === selectedPage ? 'selected' : ''}`}*/}
                {/*     onClick={() => handlePageSelection(0)}*/}
                {/*>{t('overview')}</div>*/}
                <div className={`pageSelectionButton ${1111111 === selectedPage ? 'selected' : ''}`}
                     onClick={() => handlePageSelection(1)}
                >{t('poolUtilization')}</div>
            </ButtonGroup>
        </div>
        <UserMenu styles={{position:'absolute',top:'6px',right:10}} username={username} setAdminMode={()=>{ }} adminMode={false}/>
        <div className={'vertical-separator'}></div>
        <div className={'lx-icon-container'} onClick={redirectToDashboard}>
            <div className={'prefix-title'}>{t('poweredBy')}</div>
            <img className={'image-lx'} src={LxIcon} alt={"Lynxight"}/>
        </div>

    </div>
}
ReportsHeader.propTypes = {
    selectedPage: PropTypes.number.isRequired,
    setSelectedPage: PropTypes.func.isRequired,
    orgId: PropTypes.number.isRequired
}
export default ReportsHeader