import './MFABox.scss';
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Box, IconButton} from "@mui/material";
import {verifyMFACode} from "../../../api/api";
import PropTypes from "prop-types";
import {authenticateNode, handleSuccessfulLogin} from "../../../utils/utils";
import {Button} from "@mui/material";
import OtpInput from 'react-otp-input';
import {enqueueSnackbar} from "notistack";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const MFA_TIMEOUT_SECONDS = 60
const CODE_LENGTH = 6
const MFABox = props => {
    const [t] = useTranslation()
    const [code, setCode] = useState('')
    const [time, setTime] = useState(MFA_TIMEOUT_SECONDS)
    const [token, setToken] = useState(props.token)
    const handleCodeChange = (newCode) => {
        setCode(newCode)
        if(newCode.length !== CODE_LENGTH){
            return
        }
        verifyMFACode(newCode,token).then((res)=>{
            handleSuccessfulLogin(res)
            window.location.assign(props.redirectPath)
        }).catch((exception)=>{
            if(exception?.response){
                let errorMessage = ''
                switch(exception.response.status){
                    case 401:
                        errorMessage = t('invalidMFACode')
                        break
                    case 423:
                        errorMessage = t('locked')
                        break
                    case 429:
                        errorMessage = t('tooManyAttempts')
                        break
                    default:
                        errorMessage = "Error"
                        break
                }
                enqueueSnackbar(errorMessage, {variant: 'error',anchorOrigin:{vertical: 'top', horizontal: 'right'}})
            }

            setCode('')
        })
    }

    const handleResend = async () => {
        setTime(MFA_TIMEOUT_SECONDS)
        authenticateNode(props.username,props.password).catch((ex)=> {
            if (ex.token) {
                return setToken(ex.token)
            }
            enqueueSnackbar(t('errorResendingMFA'), {variant: 'error'})
        })

    }
    useEffect(
        ()=>{
            if(time === 0){
                return
            }
            setTimeout(()=>{
                setTime(time-1)
            },1000)
        },[time]
    )

    useEffect(() => {
        setToken(props.token)
    }, [props.token]);

    return (
        <Box id={'mfa-box'} sx={{typography: 'body1'}} test-id={'mfa-box'}>
            <div className={'exit-button-wrapper'}>
                <ArrowBackIcon onClick={() => window.location.assign('/')} className={'back-button'}/>
            </div>
                <div className={'message'}>{t("codeWasSent")} {props.method.method} to {props.method.recipient}</div>
                <div className={'title'}>{t("enterMFACode")}</div>
                <OtpInput
                    shouldAutoFocus={true}
                    inputType={'number'}
                    value={code}
                    onChange={handleCodeChange}
                    containerStyle={'mfa-code-input-container'}
                    numInputs={CODE_LENGTH}
                    inputStyle={'mfa-code-input'}
                    renderInput={(props) => <input {...props} />}
                />
                <div className={'action-row'}>
                    <span>{t('noCode')} </span>
                    <Button variant={time ? 'text' : 'outlined'}
                            sx={{textTransform: "none", textAlign: 'center'}}
                            className={'resend-button'}
                            onClick={handleResend}
                            disabled={!!time}>
                        {time ? `${t('resendIn')} ${time}` : t('resend')}
                    </Button>
                </div>


        </Box>
    )
}

MFABox.propTypes = {
    token: PropTypes.string.isRequired,
    redirectPath: PropTypes.string.isRequired,
    username: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired,
    method: PropTypes.object.isRequired
}
export default MFABox;