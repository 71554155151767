import PropTypes from 'prop-types'
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip, ResponsiveContainer
} from "recharts";
import {useTranslation} from "react-i18next";
import './ConnectedWatchesGraph.scss';
import moment from "moment/moment";
import {DateTime} from "luxon";
import {getNodeContext, languageToDateRangeFormat} from "../../utils/utils";
import {useState} from "react";

const ConnectedWatchesGraph = props => {
    const [t, tObj] = useTranslation();
    const [timezone] = useState(getNodeContext().timezone)

    const parseMomentStringToDD_MM = (title) => {
        if (!title) return t('noData')
        return title && DateTime.fromISO(title).isValid && DateTime.fromISO(title).setZone(timezone).setLocale(languageToDateRangeFormat[tObj.language]).toFormat('dd-MMM')
    }
    const getHour = (title) => {
        if (!title) return t('noData')
        return title && DateTime.fromISO(title).isValid && DateTime.fromISO(title).setZone(timezone).setLocale(languageToDateRangeFormat[tObj.language]).toFormat('HH:mm') || t('noData')
    }
    const xAxisGetHour = (title) => {
        if (!title) return t('noData')
        return title && DateTime.fromISO(title).isValid && DateTime.fromISO(title).setZone(timezone).setLocale(languageToDateRangeFormat[tObj.language]).toFormat('H') || t('noData')
    }
    const toolTipFormatter = (val) => {
        return val
    }
    const labelToolTipFormatter = (val, payload) => {
        if (payload && payload.length) {
            return props.resolution === 'hours' ? getHour(payload[0].payload.created) : parseMomentStringToDD_MM(payload[0].payload.created)
        }
        return null;
    }
    return (
        <div id={'connected-watches-page'}>
            <div className={'container'}>
                <div className={'tile-title'}>{t('connected')}</div>
                <div className={'graph-container'}>
                    <ResponsiveContainer width={'95%'} height={180}>
                        <LineChart
                            data={props.connectedData}
                            margin={{
                                top: 30,
                                bottom: 15
                            }}
                        >
                            <CartesianGrid strokeDasharray="14 9" vertical={false} horizontal={true}/>
                            <Tooltip formatter={toolTipFormatter} labelFormatter={labelToolTipFormatter}/>
                            <YAxis
                                tick={{
                                    fontFamily: 'Work Sans',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    fontSize: '14px',
                                    lineHeight: '14px',
                                    textAlign: 'center',
                                    textTransform: 'capitalize',
                                    fill: '#8C939A'
                                }}
                                allowDecimals={false}
                                interval={'preserveStartEnd'}
                                tickLine={false}
                                axisLine={false}
                                type="number"
                                dataKey={'connected_count'}
                            />

                            <Line type="step" dataKey={'connected_count'} stroke="#7EB9E1" name={t('connected')}
                                  activeDot={{r: 0}} dot={false}/>
                        </LineChart>
                    </ResponsiveContainer>
                    <ResponsiveContainer width={'95%'} height={50}>
                        <LineChart
                            data={props.onHandData}
                            margin={{
                                top: 30
                            }}
                        >
                            <YAxis/>
                            <XAxis tick={{
                                fontFamily: 'Work Sans',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                fontSize: '14px',
                                lineHeight: '14px',
                                textAlign: 'center',
                                textTransform: 'capitalize',
                                fill: '#8C939A'
                            }} tickLine={false} axisLine={{stroke: '#8C939A'}}
                                   interval={props.resolution === 'hours' ? 60 : 'preserveStartEnd'} dy={10}
                                   dataKey={'created'}
                                   tickFormatter={props.resolution === 'hours' ? xAxisGetHour : parseMomentStringToDD_MM}/>
                        </LineChart>
                    </ResponsiveContainer>
                </div>
            </div>
            <div className={'container'}>
                <div className={'tile-title'}>{t('onHand')}</div>
                <div className={'graph-container'}>
                    <ResponsiveContainer width={'95%'} height={180}>
                        <LineChart
                            data={props.onHandData}
                            margin={{
                                bottom: 15,
                                top: 30
                            }}
                        >
                            <CartesianGrid strokeDasharray="14 9" vertical={false} horizontal={true}/>
                            <Tooltip formatter={toolTipFormatter} labelFormatter={labelToolTipFormatter}/>
                            <YAxis
                                tick={{
                                    fontFamily: 'Work Sans',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    fontSize: '14px',
                                    lineHeight: '14px',
                                    textAlign: 'center',
                                    textTransform: 'capitalize',
                                    fill: '#8C939A'
                                }}
                                allowDecimals={false}
                                interval={'preserveStartEnd'}
                                tickLine={false}
                                axisLine={false}
                                type="number"
                                dataKey={'on_hand_count'}
                            />
                            <Line type="step" dataKey="on_hand_count" stroke="#1F628F" name={t('onHand')}
                                  activeDot={{r: 0}} dot={false}/>
                        </LineChart>
                    </ResponsiveContainer>
                    <ResponsiveContainer width={'95%'} height={50}>
                        <LineChart
                            data={props.onHandData}
                            margin={{
                                top: 30
                            }}
                        >
                            <YAxis/>
                            <XAxis tick={{
                                fontFamily: 'Work Sans',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                fontSize: '14px',
                                lineHeight: '14px',
                                textAlign: 'center',
                                textTransform: 'capitalize',
                                fill: '#8C939A'
                            }} tickLine={false} axisLine={{stroke: '#8C939A'}}
                                   interval={props.resolution === 'hours' ? 60 : 'preserveStartEnd'} dy={10}
                                   dataKey={'created'}
                                   tickFormatter={props.resolution === 'hours' ? xAxisGetHour : parseMomentStringToDD_MM}/>
                        </LineChart>
                    </ResponsiveContainer>
                </div>
            </div>
        </div>

    );
}

ConnectedWatchesGraph.propTypes = {
    connectedData: PropTypes.array.isRequired,
    onHandData: PropTypes.array.isRequired,
    resolution: PropTypes.string.isRequired
}

export default ConnectedWatchesGraph