import './ReportsPage.scss'
import {useEffect, useState} from "react";
import ReportsHeader from "./ReportsHeader/ReportsHeader";
import {getNodeContext} from "../../utils/utils";
import ReportsOverviewPage from "./ReportsOverviewPage/ReportsOverviewPage";
import ReportsPoolUtilizationPage from "./ReportsPoolUtilizationPage/ReportsPoolUtilizationPage";
import mixpanel from "mixpanel-browser";
mixpanel.init(process.env.REACT_APP_MIXPANEL_KEY, { debug: true, track_pageview: true, persistence: 'localStorage' });

const ReportsPage = () => {

    const [selectedPage, setSelectedPage] = useState(1)
    const [context] = useState(getNodeContext() || {})
    useEffect(()=>{
        mixpanel.track_pageview({"page": "Reports"});

    },[])
    function handelSetSelectedPage(v) {
        setSelectedPage(v)
    }
    return <div id={'reports-page'}>
        <ReportsHeader setSelectedPage={handelSetSelectedPage} selectedPage={selectedPage} orgId={context.orgId}/>
        {selectedPage ? <ReportsPoolUtilizationPage/> : <ReportsOverviewPage/>  }
    </div>
}
export default ReportsPage