import './LiveSection.scss'
import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import RiskLevelTile from "../RiskLevelTile/RiskLevelTile";
import PoolDiagramTile from "../PoolDiagramTile/PoolDiagramTile";
import ActiveWatchesTile from "../ActiveWatchesTile/ActiveWatchesTile";
import {ReactComponent as LiveIcon} from "../Dashboard/live-icon.svg";
import {ReactComponent as SwimmerIcon} from "../Dashboard/swimmer-icon.svg";
import WatchOutlinedIcon from '@mui/icons-material/WatchOutlined';
import PoolGeneralTile from "../PoolGeneralTile/PoolGeneralTile";
import TotalSwimmersTileGeneral from "../TotalSwimmersTileGeneral/TotalSwimmersTileGeneral";
import ActiveWatchesTileGeneral from "../ActiveWatchesTileGeneral/ActiveWatchesTileGeneral";
import TransformationGridTile from "../TransformationGridTile/TransformationGridTile";
import {useTranslation} from "react-i18next";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import {fetchSiteLiveData} from "../../api/api";
import {getNodeContext, getNodeToken} from "../../utils/utils";

const MAX_TILES_FOR_UX = 4

const LiveSection = props => {
    const [data, setData] = useState(props.data || {})
    const [completeData, setCompeteData] = useState(props.completeData || [])
    const [originalTransformation, setOriginalTransformation] = useState([])
    const [currentTransformation, setCurrentTransformation] = useState(props.data.content && props.data.content.transformation || [])
    const [isMinimizedView] = useState(props.completeData && props.completeData.length > MAX_TILES_FOR_UX)
    const [t] = useTranslation()
    const [snackState, setSnackState] = useState(false)

    const updateTransformation = newTr => {
        setCurrentTransformation(newTr)
        data.content.transformation = newTr
        setData({...data})
    }
    const saveTransformation = async () => {
        const nodeRes = await axios.patch(`${process.env.REACT_APP_NODE_BE_PATH}/pool/transformation/${data.id}`, {
            id: data.id,
            dashboardTransformation: JSON.stringify(currentTransformation)
        }, {
            headers: {'Authorization': `Bearer ${getNodeToken()}`}
        })
        if (nodeRes && nodeRes.status === 200) {
            console.log("Node - Transformation saved successfully!!",)
            setSnackState(true)
        } else {
            alert("Node - Failed to save transformation")
            console.log("Node - Error saving transformation res", nodeRes)
        }
    }
    const getLiveData = () => {
        const siteId = getNodeContext().siteId
        fetchSiteLiveData(siteId).then(data => {
            if (!data.length) {
                return
            }

            if (data.length === 2) {
                setData(data[1])
            } else {
                setData(data[props.selectedTab])
            }
        }).catch(e => {
            console.log("Error fetching data  => e", e)
        })
    }
    const getLiveDataGeneral = () => {
        const siteId = getNodeContext().siteId
        fetchSiteLiveData(siteId).then(_data => {
            if (!_data.length) {
                return
            }
            setData(_data[0])
            setOriginalTransformation(_data[0].content.transformation)
            setCompeteData(_data.filter(e => e.id > 0))
        }).catch(e => {
            console.log("Error fetching general data", e)
        })
    }
    useEffect(() => {
        const interval = setInterval(props.isGeneral ? getLiveDataGeneral : getLiveData, 10 * 1000)
        return () => {
            clearInterval(interval)
        }
    }, [])

    const parseCoordinates = (_coordinates) => {
        if (!_coordinates || !_coordinates.length) return []
        return _coordinates.map(coo => {
            return {x: coo[0], y: coo[1]}
        });
    }

    const handleClose = () => {
        setSnackState(false)
    }
    return <div id={"live-section"}>
        {props.isGeneral && <div className={'live-section-container'}>
            <div className={'title-container margin-op'}>
                <div className={'title-inner-con'}>
                    <LiveIcon className={'icon-svg'}/>
                    <div className={'title'}>{t('live')}</div>
                </div>
                {isMinimizedView &&
                    <div key={Math.random() + '-key'} className={'header-container-right'}>
                        <div className={'header-count-container'}>
                            <SwimmerIcon className={'title-icon marginTop-4'}/>
                            <div className={'header-value'}>{t('totalSwimmers')}</div>
                            <div className={'header-value'}>{data.content.swimmersCount}</div>
                        </div>
                        <div className={'vertical-separator'}></div>
                        <div className={'header-count-container'}>
                            <WatchOutlinedIcon className={'title-icon marginTop-4'}/>
                            <div className={'header-value'}>{t('activeWatches')}</div>
                            <div className={'header-value'}>{data.content.activeWatches}</div>
                        </div>
                    </div>
                }
            </div>
            <div className={'pool-tile-container'} id={'pool-tile-container'}>
                {completeData.filter((e) => e.id > 0).map((pool, i) =>
                    <PoolGeneralTile key={Math.random() + '-key' + pool.dashboard_name}
                                     poolName={pool.dashboard_name}
                                     swimmersCount={pool.content.locations.length}
                                     isAlertsTemporaryDisabled={pool.isAlertsTempDisabled}
                                     riskValue={pool.content.riskLevel}
                                     isAtEdge={(i + 1) % 4 === 0}
                    />)}
                {!isMinimizedView && <div className={'responsive-ui-container'}>
                    <TotalSwimmersTileGeneral key={Math.random() + '-key'} value={data.content.swimmersCount}/>
                    <ActiveWatchesTileGeneral key={Math.random() + '-key'} value={data.content.activeWatches}/>
                </div>}
            </div>
        </div>}
        {!props.isGeneral && <div className={'live-section-container spread-between'}>
            <Snackbar
                open={snackState}
                autoHideDuration={5000}
                onClose={handleClose}
                message={"OK,Saved it"}
                ContentProps={{sx: {backgroundColor: '#ed7847'}}}
                style={{borderRadius: 5}}
                anchorOrigin={{horizontal: 'right', vertical: "top"}}
            />
            <RiskLevelTile isAlertsTemporaryDisabled={data.isAlertsTempDisabled}
                           key={Math.random() + "-key"}
                           riskValue={data.content && data.content.riskLevel || 0}/>
            <PoolDiagramTile maxWidth={'50%'} adminMode={props.adminMode} key={Math.random() + "-key"}
                             locations={props.adminMode ? parseCoordinates(data.coordinates) : data.content.locations}
                             transformation={data.content.transformation} poolId={data.id}
                             capacity={data.content.capacity} coordinates={data}/>
            {props.adminMode &&
                <TransformationGridTile key={Math.random() - 'key'} transformation={data.content.transformation}
                                        originalTransformation={originalTransformation}
                                        saveTransformation={saveTransformation}
                                        updateTransformation={updateTransformation} coordinates={data.coordinates}/>}
            <ActiveWatchesTile key={Math.random() + "-key"} activeWatches={data.content.activeWatches}
                               openShelf={props.handleShelf} poolName={data.dashboard_name} poolId={data.id}
                               isDrillDownActive={true}/>
        </div>}
    </div>
}
LiveSection.propTypes = {
    data: PropTypes.object.isRequired,
    handleShelf: PropTypes.func.isRequired,
    isGeneral: PropTypes.bool.isRequired,
    completeData: PropTypes.array,
    selectedTab: PropTypes.number,
}
export default LiveSection

