import './TransformationGridTile.scss'
import PropTypes from "prop-types";
import { useState} from "react";
import {Grid, IconButton} from "@mui/material";
import {ArrowDropDown,ArrowDropUp} from "@mui/icons-material";
import {AdminDotTheme} from "../../utils/Themes";
const TransformationGridTile = props => {
    const [transformation] = useState((props.transformation && props.transformation.length && props.transformation) || [[0,0,0,0],[0,0,0,0],[0,0,0,1]])

    const getCoordiantesStringArray = ()=>{
        const jsonString = JSON.stringify(props.coordinates).replaceAll('[','{').replaceAll(']','}');
        return jsonString.slice(1,jsonString.length-1).replaceAll('},{','}|{').split("|")
    }
    return <div id={'transformation-grid-tile'}>
        <Grid container rowSpacing={0} >
            {transformation.map((line, lineIndex) => {return line.map((item, itemIndex) => {
                return(
                <Grid item xs={3}>
                    <IconButton className={'grid-button'}>
                        <ArrowDropUp on onClick={()=>{
                            transformation[lineIndex][itemIndex] = parseFloat((transformation[lineIndex][itemIndex] + 1).toFixed(2))
                            props.updateTransformation(transformation)
                        }}/>
                    </IconButton>
                    <input  className={'grid-item'} value={item} type={"number"} onChange={(e) => {
                        if (!e.target.value) e.target.value = 0;
                        transformation[lineIndex][itemIndex] = parseFloat(e.target.value)
                        props.updateTransformation(transformation)
                    }}/>
                    <IconButton className={'grid-button'}>
                        <ArrowDropDown  onClick={()=>{
                            transformation[lineIndex][itemIndex] = parseFloat((transformation[lineIndex][itemIndex] - 1).toFixed(2))
                            Math.round(transformation[lineIndex][itemIndex])
                            props.updateTransformation(transformation)
                        }}/>
                    </IconButton>
                </Grid>
            )})})}
        </Grid>
        <button className={'save-button'} onClick={props.saveTransformation}>SAVE</button>
        <div >{JSON.stringify(transformation).replaceAll('[','{').replaceAll(']','}')}</div>
        <div>
            <div style={{fontWeight: 600, color:'crimson'}}> Coordinates</div>
            <div style={{color:"red"}}>{getCoordiantesStringArray().map((str,index)=>{
                return <span style={{color:AdminDotTheme[index%AdminDotTheme.length]}}>{str}{index<getCoordiantesStringArray().length-1?",":""}</span>
            })}</div>
        </div>
    </div>
}

TransformationGridTile.propTypes = {
    transformation: PropTypes.array.isRequired,
    coordinates: PropTypes.array.isRequired,
}
export default TransformationGridTile