import './LifeguardReportsTile.scss'
import PropTypes from "prop-types";
import {ReactComponent as ClipBoard} from "./clip-board.svg";
import {ReactComponent as DownArrow} from "./down-arrow.svg";
import {ReactComponent as UpArrow} from "./up-arrow.svg";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {
    dateEuFormat,
    getNodeContext,
    getStartEndDates,
    hourFormat,
    reportHeaders,
    shortString
} from "../../utils/utils";
import {ShelfTypes} from "../Dashboard/ShelfEnum";
import moment from "moment";
import OptionsMenu from "../OptionsMenu/OptionsMenu";
import {fetchSiteLifeguardReportsTableData} from "../../api/api";

const LifeguardReportsTile = props => {
    const [data] = useState(props.data || [])
    const [isDrillDownActive] = useState(props.isDrillDownActive === undefined ? true : props.isDrillDownActive)
    const [t] = useTranslation()
    const MAX_TOOLTIP_ROW_LENGTH = 16
    const [allPoolsData] = useState(props.allPoolsData || [])
    const [allPoolsView] = useState(!!props.allPoolsData)
    const [showTooltip, setShowTooltip] = useState(null)
    const [openMenu, setOpenMenu] = useState(null)
    const context = useMemo(getNodeContext, [])
    const [fullData, setFullData] = useState(null)

    useEffect(() => {
        const {start, end} = getStartEndDates(props.timeFrame)
        fetchSiteLifeguardReportsTableData(start, end, context.siteId, allPoolsView ? -1 : props.data.id).then(({data}) => {
            data?.sort((a, b) => {
                return moment(b.created) - moment(a.created)
            })
            setFullData(data)
        })

    }, []);

    function onHover(ev) {
        allPoolsData.length > 1 && allPoolsView && setShowTooltip([ev.nativeEvent.offsetX - 130, ev.nativeEvent.offsetY - 55])
    }

    function closeTooltip() {
        setShowTooltip(null)
    }

    const handleMenuClick = (e) => {
        setOpenMenu(e.currentTarget)
    }

    const handleSelection = async (selection) => {
        if (selection === 0) {
            props.openShelf(true, props.poolName, ShelfTypes.LifeguardReports, props.poolId)
        } else if (selection === 1) {
            const headers = reportHeaders.lifeguardReports
            const csvContent = "data:text/csv;charset=utf-8," + headers.join(',') + '\n' + fullData.map(row => {
                return [row.pool_name, moment(row.created).format(dateEuFormat), moment(row.created).format(hourFormat), t(row.description.toLowerCase())].join(',')
            }).join('\n')
            const encodedUri = encodeURI(csvContent);
            const link = document.createElement("a");
            link.setAttribute("href", encodedUri);
            link.setAttribute("download", props.poolName + " " + t('lifeguardReports') + ".csv");
            document.body.appendChild(link); // Required for FF
            link.click();
        }
        closeMenu()
    }
    const closeMenu = () => {
        setOpenMenu(null)
    }
    return <div id={'life-guard-reports'}>
        {isDrillDownActive && <MoreVertIcon className={'more-action'} onClick={handleMenuClick}/>}
        <div className={'title'}>{t('lifeguardReports')}</div>
        <div className={'container'}>
            <OptionsMenu closeMenu={closeMenu} anchorEl={openMenu} onSelected={handleSelection}/>

            <ClipBoard className={'clip-board'} height={180}/>
            <div className={'hover-pane'} onMouseOver={onHover} onMouseLeave={closeTooltip}/>
            {!!showTooltip && allPoolsView &&
                <div className={'tooltip'} style={{top: showTooltip[1], left: showTooltip[0]}}>
                    {allPoolsData.map(pool => pool.id > 0 &&
                        <div key={Math.random() + pool.name} className={'pool-row'}>
                            {<div key={Math.random() + pool.name}
                                  className={'pool-name'}>{shortString(pool.dashboard_name, MAX_TOOLTIP_ROW_LENGTH)}</div>}
                            {<div key={Math.random() + pool.name} className={'pool-value'}>{pool.value}</div>}
                        </div>)}
                </div>}
            <div className={'major-title-container'}>
                <div
                    className={'major-title'}>{fullData && (allPoolsView ? fullData.length : fullData.filter(record => record.pool_name === data.dashboard_name).length)}</div>
                <div className={'sub-major-title'}>{t('reports')}</div>
            </div>
        </div>
        <div className={'bottom-container'}>
            {!allPoolsView && data.delta < 0 && <UpArrow className={'arrow-icon'}/>}
            {!allPoolsView && data.delta > 0 && <DownArrow className={'arrow-icon'}/>}
            {!allPoolsView && data.delta < 0 &&
                <div className={'bottom-label-lg'}>{`${Math.abs(data.delta)}% ${t('lessThanLastPeriod')}`}</div>}
            {!allPoolsView && data.delta > 0 &&
                <div className={'bottom-label-lg'}>{`${Math.abs(data.delta)}% ${t('moreThanLastPeriod')}`}</div>}
            {!allPoolsView && data.delta === 0 &&
                <div className={'bottom-label-lg'}>{`${Math.abs(data.delta)}% ${t('sameAsLastPeriod')}`}</div>}

            {allPoolsView && allPoolsData[0] && allPoolsData[0].delta < 0 && <UpArrow className={'arrow-icon'}/>}
            {allPoolsView && allPoolsData[0] && allPoolsData[0].delta > 0 && <DownArrow className={'arrow-icon'}/>}
            {allPoolsView && allPoolsData[0] && allPoolsData[0].delta < 0 && <div
                className={'bottom-label-lg'}>{`${Math.abs(allPoolsData[0].delta)}% ${t('lessThanLastPeriod')}`}</div>}
            {allPoolsView && allPoolsData[0] && allPoolsData[0].delta > 0 && <div
                className={'bottom-label-lg'}>{`${Math.abs(allPoolsData[0].delta)}% ${t('moreThanLastPeriod')}`}</div>}
            {allPoolsView && allPoolsData[0] && allPoolsData[0].delta === 0 && <div
                className={'bottom-label-lg'}>{`${Math.abs(allPoolsData[0].delta)}% ${t('sameAsLastPeriod')}`}</div>}
        </div>
    </div>
}
LifeguardReportsTile.propTypes = {
    data: PropTypes.shape({
        id: PropTypes.number.isRequired,
        delta: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired
    }), timeFrame: PropTypes.object.isRequired, allPoolsData: PropTypes.array, isDrillDownActive: PropTypes.bool
}
export default LifeguardReportsTile