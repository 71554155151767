import './LifeguardList.scss'
import {Divider, InputAdornment, styled, TextField} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import PropTypes from "prop-types";
import {useEffect, useState} from "react";
import LifeguardItem from "./LifeguardItem/LifeguardItem";
import {useTranslation} from "react-i18next";
import {clearSpaces} from "../../../../utils/utils";
import LoadingSpinner from "../../../LoadingSpinner/LoadingSpinner";
const StyledTextField = styled(TextField)`
    input::placeholder {
        color: #6f7b83;
        opacity: 1;
        font-style: italic;
    }

    .MuiInput-underline:before {
        border-bottom: 1px solid #9DADBC;
    }

    /* hover (double-ampersand needed for specificity reasons. */

    && .MuiInput-underline:hover:before {
        border-bottom: 1px solid #9DADBC;
    }

    /* focused */
    
    .MuiInput-underline:after {
        border-bottom: 1px solid #9DADBC;
    }
`;

const defaultLifeguardPlaceholder = [{name:"Lynxight",lastName:"Support",pin:"000",acronym:"LS", passedTraining:true, email:"support@lynxight.com",phone:"+972559796529"}]
const LifeguardList = props => {
    const [searchTerm,setSearchTerm] = useState("")
    const [lifeguards,setLifeguards] = useState( null)
    const [t] = useTranslation()
    const searchByPinCode = (pinCode) => {
        return lifeguards.filter((l)=>{return l.pin.includes(pinCode)})
    }
    useEffect(()=>{
        if (props.lifeguards?.length === 0) {
            setLifeguards(defaultLifeguardPlaceholder)
        }else if (props.lifeguards?.length > 0) {
            setLifeguards(props.lifeguards)
        }
    },[props.lifeguards])

    const searchByName = (name) =>{
        return lifeguards.filter((l)=>{
            return `${l.name} ${l.lastName}`.includes(name)
        })
    }
    const search = (searchTerm)=>{
        if(!searchTerm){
            return lifeguards
        }
        let res
        const myRe = new RegExp("^\\d{1,3}$");
        if(myRe.test(searchTerm)){
            res = searchByPinCode(searchTerm)
        }
        else{
            res = searchByName(searchTerm)
        }
        if(res.length===0){
            return ""
        }
        return res

    }
    const sortLifeguards = (lifeguards) =>{
        if(!lifeguards){
            return []
        }
        let l = [...lifeguards]
        l.sort((a, b) => `${a.lastName} ${a.name}`.localeCompare(`${b.lastName} ${b.name}`));
        return l
    }
    const doesPinCodeExist = (pinCode) => {
        return lifeguards.find((lifeguard) => lifeguard.pin === pinCode)
    }
    const doesPhoneNumberExist = (phoneNumber) =>{
        return lifeguards.find((lifeguard) => clearSpaces(lifeguard.phone) === clearSpaces(phoneNumber))
    }
    const doesEmailExist = (email) =>{
        return lifeguards.find((lifeguard)=>lifeguard.email === email)
    }
    return (
        <div className={'lifeguard-list-container'}>
            <div className={"header"}>
                <span><b>{
                    lifeguards && search(searchTerm).length||0} {lifeguards?.length===1?t('lifeguard'):t('lifeguards')}</b>
                </span>
                <StyledTextField
                    value={searchTerm}
                    onChange={async (event)=>{setSearchTerm(event.target.value);search(event.target.value)}}
                    id="input-with-icon-textfield"
                    placeholder={t('searchByNameOrPinCode')}
                    InputProps={{
                        style:{color:"#9DADBC",minWidth:`${t('searchByNameOrPinCode').length*8}px`},
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                    variant="standard"
                />
            </div>
            <div className={'lifeguard-list-pane'} style={{overflowY:'scroll',height:props.isAccordionExpanded?"30.5rem":"44rem"}} >
                {searchTerm&&!search(searchTerm)&&
                    <div style={{width:"100%",textAlign:"center",color:"#9DADBC",marginTop:"1.1rem",fontFamily:"Work Sans"}}>
                        <h3>{t('noLifeguardsFound')}</h3>
                        <Divider sx={{backgroundColor:"#3D5484",marginTop:"1.1rem"}}/>
                    </div>
                }
                {lifeguards ? sortLifeguards(search(searchTerm)).map((l) => {
                        return (
                            <div key={Math.random() + 'key'}
                                 style={{marginTop: "1.1rem", display: "flex", flexDirection: "column", gap: "1.1rem"}}>
                                <LifeguardItem doesEmailExist={doesEmailExist}
                                               doesPhoneNumberExist={doesPhoneNumberExist}
                                               doesPinCodeExist={doesPinCodeExist}
                                               reload={props.reload}
                                               lifeguard={l}/>
                                <Divider sx={{backgroundColor: "#3D5484"}}/>
                            </div>
                        )
                    }
                ) : <div className={'spinner-container'}>
                    <LoadingSpinner size={150} top={10} right={75}/>
                </div>}
            </div>
        </div>
    )
}
LifeguardList.propTypes = {
    lifeguards: PropTypes.array.isRequired,
    isAccordionExpanded: PropTypes.bool,
    site:PropTypes.object.isRequired,
    reload: PropTypes.func.isRequired,
}
export default LifeguardList


