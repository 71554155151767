import './DateAndTime.scss'
import React, {useState, useEffect} from 'react'
import PropTypes from "prop-types";
import {getCurrentTime, languageToDateRangeFormat} from "../../utils/utils";
import {useTranslation} from "react-i18next";
const formatDate = 'dd MMM y | HH:mm'
const DateAndTime = props => {
    const [t, tObj] = useTranslation()
    const [dateTime, setDataTime] = useState(getCurrentTime(props.timezone, languageToDateRangeFormat[tObj.language], formatDate));
    useEffect(() => {
        setInterval(() => setDataTime(getCurrentTime(props.timezone, languageToDateRangeFormat[tObj.language], formatDate)), 1000);
    }, []);
    return <div id={'date-and-time'} style={{fontSize:props.fontSize||'18px'}}>{dateTime}</div>
}

DateAndTime.propTypes = {
    timezone: PropTypes.string
}
export default DateAndTime