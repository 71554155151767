import './DateSelector.scss'
import {Calendar} from "react-date-range";
import * as React from "react";
import {useState} from "react";
import PropTypes from "prop-types";
import useComponentVisible from "../../utils/CustomHook";
import {useTranslation} from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";

const DateSelector = (props) => {
    const [selectedDate, setSelectedDate] = useState(props.date)
    const {ref, isComponentVisible, setIsComponentVisible} = useComponentVisible(false,closeCalendar);
    const [t] = useTranslation()

    function toggleCalendar() {
        const doc = document.getElementById('calendar-pane')
        if (doc.classList.contains('expand')) {
            doc.classList.remove('expand')
            doc.classList.add('collapse')
        } else {
            doc.classList.remove('collapse')
            doc.classList.add('expand')
        }
    }


    function closeCalendar() {
        const doc = document.getElementById('calendar-pane')
        doc.classList.remove('expand')
        doc.classList.add('collapse')
    }

    let handleDateSelection = (date) => {
        props.onChange(date)
        setSelectedDate(date)
        toggleCalendar()
    };
    let parseDateTitle = () => {
        return selectedDate ? selectedDate.toDateString() : t('selectDate')
    };
    return (<div id={'date-selector'}
                 className="date-selector"
                 onBlur={closeCalendar}
    autoFocus={true}>
        <div onClick={toggleCalendar}
             onBlur={closeCalendar}
             autoFocus={true}
             className={`input-title ${selectedDate ? 'display' : ''}`}>{parseDateTitle()}
        </div>

        <div style={{position: 'absolute'}}>
            <div id={'calendar-pane'} ref={ref}
                 className={`c-pane collapse`}
                 onBlur={closeCalendar}>
                <CloseIcon className={'close-icon'} onClick={closeCalendar}/>
                <Calendar className={'calendar'}
                          clickToSelect={true}
                          clickToClose={true}
                          minDate={new Date()}
                          date={selectedDate}
                          onChange={handleDateSelection}
                />
            </div>
        </div>

    </div>)
}
DateSelector.propTypes = {
    date: PropTypes.object, onChange: PropTypes.func.isRequired, title: PropTypes.string
}
export default DateSelector