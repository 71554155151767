import './TotalSwimmersTileGeneral.scss'
import {ReactComponent as TotalSwimmersIcon} from "./totalSwimmersIcon.svg";
import PropTypes from "prop-types";
import {useState} from "react";
import {useTranslation} from "react-i18next";


const TotalSwimmersTileGeneral = props=>{
    const [value] = useState(props.value)
    const [t] = useTranslation()
    return <div id={'total-swimmers-tile-general'}>
        <div className={'value-title-container'}>
            <div className={'value-title'}>{value}</div>
            <div className={'bottom-title'}>{t('swimmers')}</div>
        </div>
        <TotalSwimmersIcon className={'icon'}/>
    </div>
}
TotalSwimmersTileGeneral.propTypes = {
    value: PropTypes.number.isRequired
}

export default TotalSwimmersTileGeneral