import './UserTitle.scss'
import React  from 'react';
import PropTypes from "prop-types";

const UserTitle = props => {
    return <div id={'company-name'}>
        {/*<div className={'user-title'}>{props.orgName || 'Organization name'}</div>*/}
        {/*<div className={'vertical-separator margin-o'}/>*/}
        <div test-id={'site-name'} className={'user-title'} style={{fontSize:props.fontSize||'18px'}}>{props.siteName || 'Site-name'}</div>
        {/*<div className={'vertical-separator margin-o'}/>*/}
        {/*<div className={'user-title'}>{props.username|| 'username'}</div>*/}

    </div>
}
UserTitle.propTypes = {
    username: PropTypes.string.isRequired,
    orgName: PropTypes.string.isRequired,
    siteName: PropTypes.string.isRequired,
    fontSize: PropTypes.string,

}
export default UserTitle