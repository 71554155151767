export const AdminDotTheme =
    [
        "#f57527","#1a605c",
        "#ff0000","#ffdd00",
        "#9ff587","#af00ff",
        "#0006ff","#29a406",
    ]
export const alertPropsMap = {
    warning: {color: '#d92d2d', heatmapSize:2,opacity:0.91},
    attention: {color: '#ecdf56', heatmapSize:2,opacity:0.91},
    overcrowding: {color: '#0f6faf', heatmapSize:6,opacity:0.85}
}
