import './ReportsGraph.scss'
import {PropTypes} from "prop-types";
import {useTranslation} from "react-i18next";
import {Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import moment from "moment/moment";
import {useState} from "react";
import {getNodeContext, languageToDateRangeFormat} from "../../utils/utils";
import {DateTime} from "luxon";

const ReportsGraph = props => {
    const [t, tObj] = useTranslation();
    const [timezone] = useState(getNodeContext().timezone)

    const parseMomentStringToDD_MM = (title) => {
        if (!title) return t('noData')
        return (title && DateTime.fromISO(title).isValid && DateTime.fromISO(title).setZone(timezone).setLocale(languageToDateRangeFormat[tObj.language]).toFormat('dd-MMM')) || t('noData')
    }
    const getHour = (title) => {
        return (title && DateTime.fromISO(title).isValid && DateTime.fromISO(title).setZone(timezone).setLocale(languageToDateRangeFormat[tObj.language]).toFormat('HH:mm')) || t('noData')

    }
    const getStartOfHour = (title) => {
        return (title && DateTime.fromISO(title).isValid && DateTime.fromISO(title).setZone(timezone).setLocale(languageToDateRangeFormat[tObj.language]).toFormat('HH:mm')) || t('noData')
    }

    const CustomTooltip = ({active, payload, label}) => {
        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip">
                    <div className={'tooltip-row'}>
                        <div className={'label'}>{(props.resolution === 'hours' ? t('time') + '\t' : t('date') + '\t')}</div>
                        <div
                            className={'item'}>{(props.resolution === 'hours' ? getHour(payload[0].payload.created) : parseMomentStringToDD_MM(payload[0].payload.created))}</div>
                    </div>
                    <div className={'tooltip-row'}>
                        <div className={'label'}>{props.graphName === 'safetyEvents' ? t('events') + '\t' : t('reports') + '\t'}</div>
                        <div className={'item'}>{payload[0].value}</div>
                    </div>
                </div>
            );
        }

        return null;
    };
    return (
        <div id={'reports-graph-page'}>
            <div className={'container'} key={'key-' + Math.random()}>
                <div className={'tile-title'}>{t(props.graphName)}</div>
                <ResponsiveContainer width={'95%'} maxHeight={260}>
                    <BarChart data={props.data} width={100} margin={{top: 35, right: 25, left: 0, bottom: 0}}>
                        <CartesianGrid strokeDasharray="1 4" vertical={false}/>
                        <XAxis tickFormatter={props.resolution === 'days' ? parseMomentStringToDD_MM : getStartOfHour}
                               dataKey="created"/>
                        <YAxis axisLine={false}/>
                        <Tooltip content={<CustomTooltip/>}/>
                        <Bar dataKey="count" fill="#57A6DB"/>
                    </BarChart>
                </ResponsiveContainer>
            </div>
        </div>
    )
}

ReportsGraph.propTypes = {
    graphName: PropTypes.string.isRequired,
    resolution: PropTypes.string.isRequired,
    data: PropTypes.object.isRequired
}

export default ReportsGraph;