const ShelfTypes = Object.freeze({
	SwimmerHistory: 0,
	SafetyEvents: 1,
	LifeguardReports: 2,
	ActiveWatches: 3
});
const ShelfStrings = Object.freeze({
    0: 'swimmerCount',
	1: 'safetyEvents',
	2: 'lifeguardReports',
	3: 'connectedWatches'
})
export {ShelfTypes, ShelfStrings};