import './HeaderStatic.scss'
import DateAndTime from '../DateAndTime/DateAndTime';
import UserTitle from '../UserTitle/UserTitle';
import PropTypes from 'prop-types';
import {envMap} from "../../utils/utils";

const baseLogoUrl = process.env.REACT_APP_IMAGE_STORAGE_PATH + 'icons/' + envMap[process.env.NODE_ENV] + '/'

const HeaderStatic = props => {
    const context = props.context
    function handleImageError() {
        const img = document.getElementById('company-logo')
        img.src = baseLogoUrl + `icon_org_id_lx.svg`
    }

    const iconUrl = `${baseLogoUrl}${ `icon_org_id_${props.context.orgId}.svg`}`
    return <div id={'main-header-static'}>
        <img id={'company-logo'} className={'company-icon'} src={iconUrl}
             onError={handleImageError} alt={'Company-icon'}/>
        <div className={'vertical-separator left'}/>

        <DateAndTime fontSize={'1.5rem'} timezone={context.timezone}/>
        <UserTitle fontSize={'1.5rem'} username={props.context.username} orgName={props.context.orgName}
              siteName={ props.context.siteTitle +' - '+props.poolName}/>
    </div>
}
HeaderStatic.propTypes = {
    context: PropTypes.object.isRequired,
    setAdminMode: PropTypes.func,
    fontSize: PropTypes.string,
}
export default HeaderStatic