import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import {useTranslation} from "react-i18next";
import {handleLogout} from "../../utils/utils";

const ConfirmLogout = props => {

    const [ t ] = useTranslation();
    return (
        <Dialog
            open={props.openState}
            onClose={props.handleConfirmClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
        <DialogTitle id="alert-dialog-title">
        {t('loggingOut')}
        </DialogTitle>
        <DialogContent>
        <DialogContentText id="alert-dialog-description">
            {t('logoutConfirmationMessage')}
        </DialogContentText>
        </DialogContent>
        <DialogActions>
        <Button onClick={props.handleConfirmClose} autoFocus>{t('no')}</Button>
        <Button onClick={handleLogout} >
            {t('yes')}
        </Button>
        </DialogActions>
    </Dialog>
    )
}

ConfirmLogout.propTypes = {}

export default ConfirmLogout


