import PropTypes from "prop-types";
import {useEffect} from "react";
import './ReportsDiagramPane.scss'
import {useTranslation} from "react-i18next";
import h337 from "heatmap.js";

import {transformLinearVector} from "../../../utils/LinearCalcHelper";
import {alertPropsMap} from "../../../utils/Themes";
import LoadingSpinner from "../../LoadingSpinner/LoadingSpinner";

const url = process.env.REACT_APP_IMAGE_STORAGE_PATH + 'pools/prod/'

const ReportsDiagramPane = props => {
    const [t] = useTranslation()
    const transformationDimensions = {height: 710, width: 1380}
    const height = 250
    const layoutDimensions = {
        height: height,
        width: height * transformationDimensions.width / transformationDimensions.height
    }
    const adjustmentHeight = -20
    const adjustmentWidth = -5
    const scale = {
        x: (layoutDimensions.width) / transformationDimensions.width,
        y: (layoutDimensions.height) / transformationDimensions.height
    }
    let heatmapInstance;
    useEffect(() => {
        heatmapInstance = h337.create({
        container: document.getElementById('heatmap-element'),
        width: layoutDimensions.width-15,
        height:height-15,
            blur:0.9,
            radius:40 - (5* Math.sqrt(props.poolArea || 100) / 10),
            minOpacity:1,
        gradient: {0.1: '#002852', 0.3: '#002852', 0.92: 'rgb(65,143,222)',   0.98: 'rgb(154,204,239)'}
    });
            heatmapInstance.setData({max: props.data.max*0.7 , data: props.data.data });
        return () => {
            const docs = document.getElementById('heatmap-element')
                const elements = docs && docs.children || []
            for (let i = 1; i < elements.length; i++) {
                elements[i].remove()
            }
        }
    },[props.data])

    function handleImageError() {
        const img = document.getElementById('pool-image')
        img.src = url + `pool_id_blue_0.svg`
    }

    return <div id={'reports-diagram-pane'}>
        <div className={'pool-image-container'}>
            <div className={'pool-view-title'}>{t('swimmersHeatmap')}</div>
            <div className={'heatmap-container'}>
                <div className={'alerts-pane'}>
                    {props.alerts?.map((alert) => {
                            const vector = {x: alert.location[0], y: alert.location[1], z: 0, w: 0}
                            const point = transformLinearVector(props.transformation, vector)
                            const adjustedPoint = {
                                x: adjustmentWidth + (scale.x * point.x),
                                y: adjustmentHeight + (scale.y * point.y)
                            }
                            const alertProps = alertPropsMap[alert.description.toLowerCase()]
                            return <div className={'alert-point'}
                                        style={{
                                            top: adjustedPoint.y,
                                            left: adjustedPoint.x,
                                            backgroundColor: alertProps.color,
                                            height: alertProps.heatmapSize,
                                            width: alertProps.heatmapSize,
                                            opacity: alertProps.opacity
                                        }}/>
                        }
                    )}
                </div>
                {props.data.max<0 && <LoadingSpinner top={120} right={20}/>}
                <div id={'heatmap-element'} className={'swimmers-layout'}>
                    <img style={{width: layoutDimensions.width, height}} id={'pool-image'}
                         className={'img-pool'}
                         src={url + 'pool_id_blue_' + props.poolId + '.svg'}
                         onError={handleImageError} alt="Pool-Image"/>
                </div>
            </div>
            <div className={'legend-container'}>
                <div className={'label'}>{t('low')}</div>
                <div className={'gradient'}/>
                <div className={'label'}>{t('high')}</div>
            </div>
        </div>
    </div>
}
ReportsDiagramPane.propTypes = {
    alerts: PropTypes.array,
    poolId: PropTypes.number.isRequired,
    poolArea: PropTypes.number.isRequired,
    data: PropTypes.object.isRequired,
    transformation: PropTypes.array.isRequired
}
export default ReportsDiagramPane