import {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import './Shelf.scss';
import HeatmapChart from '../HeatmapChart/HeatmapChart';
import {ShelfTypes, ShelfStrings} from '../Dashboard/ShelfEnum';
import {useTranslation} from "react-i18next";
import SimpleDatePicker from '../SimpleDatePicker/SimpleDatePicker';
import SwimmersCountHistoryTile from "../SwimmersCountHistoryTile/SwimmersCountHistoryTile";
import moment from "moment/moment";
import ConnectedWatchesGraph from "../ConnectedWatchesGarph/ConnectedWatchesGraph";
import ReportsGraph from "../ReportsGraph/ReportsGraph";
import ReportsTable from "../ReportsTable/ReportsTable";
import SafetyEventsTile from "../SafetyEventsTile/SafetyEventsTile";
import {Backdrop, CircularProgress} from "@mui/material";
import {
    fetchPoolConnectedWatchesGraphData, fetchPoolHeatmapData,
    fetchSiteLifeguardReportsGraphData, fetchSiteLifeguardReportsTableData,
    fetchSiteSafetyEventsFilterByPoolId,
    fetchSiteSafetyEventsGraphData,
    fetchSiteSafetyEventsTableData,
    fetchSiteSwimmerHistoryFilterByPoolId,
} from "../../api/api";
import {getNodeContext, getStartEndDates} from "../../utils/utils";


const Shelf = props => {
    const [historyData, setHistoryData] = useState({data: []})
    const [timeFrame, setTimeFrame] = useState({start: moment().startOf('day'), end: moment().endOf('day')})
    const [heatmapData, setHeatmapData] = useState({max: 0, data: []});
    const [connectedWatchesData, setConnectedWatchesData] = useState({
        resolution: '',
        data: {connected: [], on_hand: []}
    })
    const [isBackDropOpen, setIsBackDropOpen] = useState(false);
    const [safetyEventsGraphData, setSafetyEventsGraphData] = useState({data: [], resolution: ''})
    const [lifeguardReportsGraphData, setLifeguardReportsGraphData] = useState({data: [], resolution: ''})
    const [lifeguardReportsTableData, setLifeguardReportsTableData] = useState({data: [], resolution: ''})
    const [safetyEventsTableData, setSafetyEventsTableData] = useState({data: [], resolution: ''})
    const [safetyEventsPieData, setSafetyEventsPieData] = useState({
        delta: 0,
        warning: 0,
        attention: 0,
        overcrowding: 0,
        hazard: 0,
        id: props.header.poolId

    });
    const [t] = useTranslation()
    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        props.handleCloseShelf(open);
    };

    const fetchShelfData = ({start, end}) => {
        const nodeContext = getNodeContext()
        if (ShelfTypes.ActiveWatches === props.header.shelfType) {
            Promise.all(
                [
                    fetchPoolConnectedWatchesGraphData(start, end, props.header.poolId)
                ]
            ).then(([_connectedWatchesData]) => {
                setConnectedWatchesData(_connectedWatchesData)
                toggleBackDrop(false)
            }).catch(ex => {
                console.log("Error fetching data in useEffect: ", ex)
            })
        }
        if (ShelfTypes.SwimmerHistory === props.header.shelfType) {
            Promise.all([
                fetchSiteSwimmerHistoryFilterByPoolId(nodeContext.siteId, start, end, props.header.poolId),
                fetchPoolHeatmapData(start, end, props.header.poolId)
            ]).then(([_historyData, _heatmapData,]) => {
                setHistoryData(_historyData)
                setHeatmapData(_heatmapData)
                toggleBackDrop(false)
            }).catch(ex => {
                console.log("Error fetching data in useEffect: ", ex)
            })
        }
        if (ShelfTypes.SafetyEvents === props.header.shelfType) {
            Promise.all([
                fetchSiteSafetyEventsGraphData(start, end, nodeContext.siteId, props.header.poolId),
                fetchSiteSafetyEventsTableData(start, end, nodeContext.siteId, props.header.poolId),
                fetchSiteSafetyEventsFilterByPoolId(nodeContext.siteId, start, end, props.header.poolId)
            ]).then(([_safetyEventsGraphData, _safetyEventsTableData, _safetyEventsPieData]) => {
                console.log('sean .. _safetyEventsGraphData, _safetyEventsTableData, _safetyEventsPieData', _safetyEventsGraphData, _safetyEventsTableData, _safetyEventsPieData)
                setSafetyEventsGraphData(_safetyEventsGraphData)
                setSafetyEventsTableData(_safetyEventsTableData)
                setSafetyEventsPieData(_safetyEventsPieData)
                toggleBackDrop(false)
            }).catch(ex => {
                console.log("Error fetching data in useEffect: ", ex)
            })
        }
        if (ShelfTypes.LifeguardReports === props.header.shelfType) {
            Promise.all([
                fetchSiteLifeguardReportsGraphData(start, end, nodeContext.siteId, props.header.poolId), fetchSiteLifeguardReportsTableData(start, end, nodeContext.siteId, props.header.poolId)
            ]).then(([_lifeguardReportsGraphData, _lifeguardReportsTableData]) => {
                setLifeguardReportsGraphData(_lifeguardReportsGraphData)
                setLifeguardReportsTableData(_lifeguardReportsTableData)
                toggleBackDrop(false)
            }).catch(ex => {
                console.log("Error fetching data in useEffect: ", ex)
            })
        }
    }
    const clearHeatmapData = () => {
        setHeatmapData({max: 0, data: []})
    }
    const toggleBackDrop = (_state) => {
        setIsBackDropOpen(_state)
    }
    const handleSetTimeFrame = (value) => {
        clearHeatmapData();
        toggleBackDrop(true)
        fetchShelfData(getStartEndDates(value))
        setTimeFrame(value)
    }
    useEffect(() => {
        if (!props.isOpen) return
        clearHeatmapData()
        const {start, end} = getStartEndDates(props.timeFrame)
        fetchShelfData({start, end})
        setTimeFrame(props.timeFrame);
    }, [props.isOpen])

    return <Drawer
        PaperProps={{
            sx: {
                width: "66%", background: "#F7F8FC",
                boxShadow: "1px 0px 36px rgba(0, 0, 0, 0.24)",
                borderRadius: "0px",
                overflowX: 'hidden', overflowY: 'hidden'
            },
        }}
        variant={'temporary'}
        anchor={'right'}
        open={props.isOpen}
        onClose={toggleDrawer(false)}
    >
        <div id={'shelf-page'}>
            <div className={"header"}>
                <div
                    className={'header-text'}>{props.header.poolId === -1 ? t('allPools') : props.header.poolName} - {t(ShelfStrings[props.header.shelfType])}</div>
                <Button className={'close-button'} onClick={toggleDrawer(false)}>
                    <CloseIcon/>
                </Button>
            </div>
            <Backdrop
                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer - 1}}
                open={isBackDropOpen}
                onClick={() => toggleBackDrop(false)}
            >
                <CircularProgress color="inherit"/>
            </Backdrop>
            <div className={'main-pane'} key={props.header.poolName + 50 + Math.random()}>
                <div className={'minor-header'}>
                    <SimpleDatePicker timeFrame={timeFrame} setTimeFrame={handleSetTimeFrame}/>
                    {ShelfTypes.ActiveWatches === props.header.shelfType &&
                        <div className={'active-watches-legend'}>
                            <div className={'legend-container'}>
                                <div className={'cube light-blue'}/>
                                &nbsp;
                                <div>{t('connected')}</div>
                            </div>
                            <div className={'legend-container'}>
                                <div className={'cube navy'}/>
                                &nbsp;
                                <div>{t('onHand')}</div>
                            </div>
                        </div>
                    }
                </div>
                <div className={'data'}>
                    {ShelfTypes.SwimmerHistory === props.header.shelfType &&
                        <div>
                            <SwimmersCountHistoryTile data={historyData || {}} openShelf={() => {
                            }} poolName={props.header.poolName} poolId={props.header.poolId} isDrillDownActive={false}/>
                            <HeatmapChart data={heatmapData||{}} transformation={props.transformation}
                                          poolId={props.header.poolId}/>
                        </div>
                    }
                    {ShelfTypes.ActiveWatches === props.header.shelfType && props.header.poolId !== 0 &&
                        <div style={{overflowY: 'auto'}}>
                            {
                                <ConnectedWatchesGraph
                                    resolution={connectedWatchesData.resolution}
                                    connectedData={connectedWatchesData.data.connected || {}}
                                    onHandData={connectedWatchesData.data.on_hand || {}}/>
                            }
                        </div>
                    }
                    {ShelfTypes.SafetyEvents === props.header.shelfType && props.header.poolId !== 0 &&
                        <div>
                            <ReportsGraph graphName={'safetyEvents'} resolution={safetyEventsGraphData.resolution}
                                          data={safetyEventsGraphData.data || []}/>
                            <div className={'safety-event-bottom-container'}>
                                <ReportsTable resolution={safetyEventsGraphData.resolution}
                                              data={safetyEventsTableData.data || []}
                                              isAllPools={props.header.poolId === -1}/>
                                <SafetyEventsTile data={safetyEventsPieData || {
                                    delta: 0,
                                    warning: 0,
                                    attention: 0,
                                    overcrowding: 0,
                                    hazard: 0,
                                    id: props.header.poolId
                                }} isDrillDownActive={false} openShelf={() => {
                                }} poolName={props.header.poolName} poolId={props.header.poolId} isVertical={true}/>
                            </div>

                        </div>

                    }
                    {ShelfTypes.LifeguardReports === props.header.shelfType && props.header.poolId !== 0 &&
                        <div>
                            <ReportsGraph graphName={'lifeguardReports'}
                                          resolution={lifeguardReportsGraphData.resolution}
                                          data={lifeguardReportsGraphData.data || {}}/>
                            <ReportsTable resolution={lifeguardReportsTableData.resolution}
                                          data={lifeguardReportsTableData.data || []}
                                          isAllPools={props.header.poolId === -1}/>
                        </div>

                    }
                </div>
            </div>
        </div>
    </Drawer>
}

Shelf.propTypes = {
    handleCloseShelf: PropTypes.func.isRequired,
    header: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    timeFrame: PropTypes.object.isRequired,
    transformation: PropTypes.array.isRequired
}
export default Shelf;