import './SwimmersCountHistoryTileStatic.scss'
import PropTypes from "prop-types";
import {
    LineChart,
    CartesianGrid,
    Line,
    XAxis,
    YAxis,
    ResponsiveContainer
} from "recharts";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {
    getNodeContext,
    languageToDateRangeFormat, mockDataSwimmersHistory,
} from "../../utils/utils";
import {DateTime} from "luxon";
const SwimmersCountHistoryTileStatic = props => {
    const [t, tObj] = useTranslation()
    const [timezone] = useState(getNodeContext().timezone)
    const getStartOfHour = (title) => {
        return title && DateTime.fromISO(title).isValid && DateTime.fromISO(title).setZone(timezone).setLocale(languageToDateRangeFormat[tObj.language]).toFormat('HH:mm')
    }
    useEffect(() => {
    }, [props.data.data,props.testMode]);

    return <div id='swimmers-count-history-tile-static'>
        <div className={'container'}>
            <div className={'tile-title'}>Swimmer Count History</div>
            <ResponsiveContainer width={'100%'} height={340}>
                <LineChart data={props.testMode?mockDataSwimmersHistory:props.data.data} margin={{top: 55, right: 25, left: 0, bottom: 0}}>
                    <CartesianGrid strokeDasharray="14 9" vertical={false}/>
                    <XAxis interval={props.testMode?mockDataSwimmersHistory.length >= 60 ? 11 : 5 : props.data.data.length >= 60 ? 11 : 5} tick={{fontSize: 14}} dataKey="time"
                           tickFormatter={getStartOfHour}/>
                    <YAxis axisLine={false}/>
                    <Line dot={false} type="monotone" dataKey="swimmer_count" strokeWidth={3} stroke="#57A6DB"/>
                </LineChart>
            </ResponsiveContainer>
        </div>
    </div>
}
SwimmersCountHistoryTileStatic.propTypes = {
    data: PropTypes.object.isRequired,
    timeFrame: PropTypes.object.isRequired,
}
export default SwimmersCountHistoryTileStatic