import './OptionsMenu.scss'
import PropTypes from "prop-types";
import {useState} from "react";
import {Menu, MenuItem} from "@mui/material";
import {useTranslation} from "react-i18next";
const defaultMenuItems  = [{id:0, title:'moreInfo'},{id:1, title:'download'}]
const OptionsMenu = props=>{
    const [menuItems] = useState(props.menuItems || defaultMenuItems)
    const [t] = useTranslation()

    const handleCloseMenu= (e)=> {
        setTimeout(props.closeMenu,300)
    }

    return <Menu anchorEl={props.anchorEl} open={!!props.anchorEl} style={{position:'absolute'}} onBlur={handleCloseMenu} >
        {menuItems.filter((e) => props.disableDownload ? e.id < 1 : true).map(menuItem =>{
            return <MenuItem key={menuItem.id+Math.random().toString()} onClick={()=>props.onSelected(menuItem.id)}>{t(menuItem.title,)}</MenuItem>
        })}
    </Menu>
}

OptionsMenu.propTypes = {
    menuItems: PropTypes.array,
    anchorEl: PropTypes.object,
    onSelected: PropTypes.func.isRequired,
    closeMenu: PropTypes.func.isRequired,
    disableDownload: PropTypes.bool
}
export default OptionsMenu