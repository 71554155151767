import './ReportsTable.scss'
import {PropTypes} from "prop-types";
import {useTranslation} from "react-i18next";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TablePagination,
    TableRow,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import {useEffect, useMemo, useState} from "react";
import TablePaginationActions from "./TablePagination";
import TablePaginationToolbar from "./TablePaginationToolbar";
import ReportsTableHead from "./ReportsTableHead";
import {DateTime} from "luxon";
import {getNodeContext, languageToDateRangeFormat} from "../../utils/utils";

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function tableSort(array, comparator) {
    const mappedArray = array.map((el, index) => [el, index]);
    mappedArray.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return mappedArray.map((el) => el[0]);
}

const ReportsTable = props => {
    const [t, tObj] = useTranslation()
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(6);
    const emptyRows = Math.max(0, (1 + page) * rowsPerPage - props.data.length)
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('date');
    const [timezone] = useState(getNodeContext().timezone)
    const parseMomentStringToDD_MM = (title) => {
        if (!title) return t('noData')
        return title && DateTime.fromISO(title).isValid && DateTime.fromISO(title).setZone(timezone).setLocale(languageToDateRangeFormat[tObj.language]).toFormat('dd-MMM')
    }
    const getHour = (title) => {
        return title && DateTime.fromISO(title).isValid && DateTime.fromISO(title).setZone(timezone).setLocale(languageToDateRangeFormat[tObj.language]).toFormat('HH:mm') || t('noData')

    }
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleRequestSort = (event, property, direction) => {
        setOrder(direction);
        setOrderBy(property);
    };
    useEffect(() => {
        handleRequestSort(undefined, 'created', 'asc');
    }, [])
    const renderTableRows = () => {
        return (rowsPerPage > 0
                ? tableSort(props.data, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                : tableSort(props.data, getComparator(order, orderBy))
        ).map((row) => (
            <TableRow key={'table-row-' + Math.random()}>
                {props.isAllPools &&
                    <TableCell style={{width: 160}} className={'cell-text'}>
                        {row.pool_name}
                    </TableCell>
                }
                <TableCell style={{width: 160}} className={'cell-text'}>
                    {parseMomentStringToDD_MM(row.created)}
                </TableCell>
                <TableCell style={{width: 160}} className={'cell-text'}>
                    {getHour(row.created)}
                </TableCell>
                <TableCell style={{width: 160}} className={'cell-text'}>
                    {row.description}
                </TableCell>
            </TableRow>
        ))

    }
    const tableRows = useMemo(renderTableRows, [page, orderBy, order])
    return (
        <div id={'reports-table-page'}>
            <TableContainer className={'container'} component={Paper} sx={{overflowY: 'auto'}}>
                <Table sx={{minWidth: 500}} aria-label="custom pagination table" sx={{overflowY: 'auto'}}>
                    <ReportsTableHead isSinglePoolView={!props.isAllPools} onRequestSort={handleRequestSort}
                                      order={order} orderBy={orderBy}/>
                    <TableBody>
                        {tableRows}
                        {emptyRows > 0 && (
                            <TableRow style={{height: 53 * emptyRows}}>
                                <TableCell colSpan={6}/>
                            </TableRow>
                        )}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                colSpan={3}
                                count={props.data.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                    inputProps: {
                                        'aria-label': 'rows per page',
                                    },
                                    native: true,
                                }}
                                component={TablePaginationToolbar}
                                onPageChange={handleChangePage}
                                ActionsComponent={TablePaginationActions}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </div>
    )
}

ReportsTable.propTypes = {
    data: PropTypes.object.isRequired,
    isAllPools: PropTypes.bool.isRequired
}


export default ReportsTable;