import './SwimmersCountHistoryTile.scss'
import PropTypes from "prop-types";
import {
    Bar,
    BarChart,
    LineChart,
    CartesianGrid,
    Line,
    Tooltip,
    XAxis,
    YAxis,
    ResponsiveContainer
} from "recharts";
import {useState, useMemo} from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {useTranslation} from "react-i18next";
import moment from "moment";
import { ShelfTypes } from '../Dashboard/ShelfEnum';
import OptionsMenu from "../OptionsMenu/OptionsMenu";
import {
    dateEuFormat, getNodeContext,
    getStartEndDates,
    hourFormat, languageToDateRangeFormat,
    reportHeaders
} from "../../utils/utils";
import { fetchSiteSwimmerHistoryFilterByPoolId} from "../../api/api";
import {DateTime} from "luxon";

const SwimmersCountHistoryTile = props => {
    const [data] = useState(props.data.data)
    const [isDrillDownActive] = useState(props.isDrillDownActive === undefined?true:props.isDrillDownActive)
    const [isGeneralTile] = useState(props.data.id === -1)
    const [toolTipData] = useState(props.data.toolTipData)
    const [resolution] = useState(props.data.resolution)
    const [openMenu,setOpenMenu] = useState(null)

    const [t, tObj] = useTranslation()
    const [timezone] = useState(getNodeContext().timezone)
    const context = useMemo(getNodeContext, [])


    const parseTooltipTitle = (title, value,daysFormat) => {
        if (value || value === 0) {
            return (daysFormat? parseMomentStringToDD_MM(title):getHour(title) )+ ' | Total:  ' + value
        } else {
            return 'N/A'
        }
    }
    const parseMomentStringToDD_MM = (title) => {
        if (!title) return t('noData')
        return title && DateTime.fromISO(title).isValid && DateTime.fromISO(title).setZone(timezone).setLocale(languageToDateRangeFormat[tObj.language]).toFormat('dd-MMM')
    }
    const getHour = (title) => {
        return title && DateTime.fromISO(title).isValid && DateTime.fromISO(title).setZone(timezone).setLocale(languageToDateRangeFormat[tObj.language]).toFormat('HH:mm') || t('noData')

    }
    const getStartOfHour = (title) => {
        return title && DateTime.fromISO(title).isValid && DateTime.fromISO(title).setZone(timezone).setLocale(languageToDateRangeFormat[tObj.language]).toFormat('HH:mm')
    }
    const CustomTooltip = ({label,days}) => {
        if (label) {
            const relevantData =toolTipData && toolTipData.length && toolTipData.find(e => e.date === label)
            if (!relevantData){
              return null
            }
            relevantData.total = relevantData.data.reduce((a, b) => a + b.value, 0)
            return <div id={'tool-tip'}>
                {relevantData && relevantData.data ? <div className={'tooltip-container'}>
                    <div className={'tooltip-title'}>{parseTooltipTitle(label, relevantData.total,days)}</div>
                    {relevantData.data.map(el => <div key={Math.random() + '-key'} className={'inner-box'}>
                        <div className={'item-name'}>{el.pool}</div>
                        <div className={'item-value'}>{el.value}</div>
                    </div>)
                    }</div> : null}
            </div>
        } else return null
    }

    const handleMenuClick = (e)=>{
        setOpenMenu(e.currentTarget)
    }
    const DailyTooltip = ({active, payload, label}) => {
        if (active && payload && payload.length) {
            return (
                <div className="tooltip-container">
                    <div className="tooltip-title">{`${parseMomentStringToDD_MM(label)}`}</div>
                    <div className={'inner-box'}>
                        <div className={'item-name'}>{t('maximum')}</div>
                        <div className={'item-value'}>{`${payload[0].value}`}</div>
                    </div>
                </div>
            );
        }
        return null
    }
    const HourlyTooltip = ({active, payload, label}) => {
        if (active && payload && payload.length) {
            return (
                <div className="tooltip-container">
                    <div className="tooltip-title">{`${getHour(label)}`}</div>
                    <div className={'inner-box'}>
                        <div className={'item-name'}>{t('total')}</div>
                        <div className={'item-value'}>{` ${payload[0].value}`}</div>
                    </div>
                </div>
            );
        }
        return null
    }
    const handleSelection = async (selection) => {
        if (selection === 0) {
            props.openShelf(true, props.poolName, ShelfTypes.SwimmerHistory, props.poolId)
        }else if (selection === 1) {
                const headers = reportHeaders.swimmersHistory
                const {start, end} = getStartEndDates(props.timeFrame)
                let {data} = await fetchSiteSwimmerHistoryFilterByPoolId(context.siteId, start, end, props.data.id)
                data = data.filter((l) => l)
                data.sort((a,b)=>{
                    return moment(a.time) - moment(b.time) })
                const csvContent = "data:text/csv;charset=utf-8," + headers.join(',')+'\n'+data.map(row =>{
                    return [moment(row.time).format(dateEuFormat),moment(row.time).format(hourFormat),row.swimmer_count].join(',')
                }).join('\n')
                const encodedUri = encodeURI(csvContent);
                const link = document.createElement("a");
                link.setAttribute("href", encodedUri);
                link.setAttribute("download", `${props.poolName} ${t('swimmerCountHistory')}.csv`);
                document.body.appendChild(link); // Required for FF
                link.click();
            }
            closeMenu()
    }
    const closeMenu = ()=>{
        setOpenMenu(null)
    }

    return <div id='swimmers-count-history-tile'>
        <div className={'container'}>
            <div className={'tile-title'}>{resolution==='days'?t('swimmersCountMax'):t('swimmersCount')}</div>
            { isDrillDownActive && <MoreVertIcon className={'more-action'}  onClick={handleMenuClick}/>}
            <OptionsMenu closeMenu={closeMenu} anchorEl={openMenu} onSelected={handleSelection} disableDownload={isGeneralTile}/>
            {resolution === 'days' &&
                <ResponsiveContainer width={'95%'} height={260}>
                    <BarChart data={data} margin={{top: 35, right: 25, left: 0, bottom: 0}}>
                        <CartesianGrid strokeDasharray="14 9" vertical={false}/>
                        <XAxis tickFormatter={parseMomentStringToDD_MM} dataKey="time"/>
                        <YAxis axisLine={false} />
                        {isGeneralTile ? <Tooltip content={<CustomTooltip days/>}/> : <Tooltip content={<DailyTooltip/>}/>}
                        <Bar dataKey="swimmer_count" fill="#57A6DB" maxBarSize={60}/>
                    </BarChart>
                </ResponsiveContainer>
            }
            {resolution === 'hours' &&
                <ResponsiveContainer width={'95%'} height={260}>
                    <LineChart data={data} margin={{top: 35, right: 25, left: 0, bottom: 0}}>
                        <CartesianGrid strokeDasharray="14 9" vertical={false}/>
                        <XAxis interval={data.length >= 60 ? 11 : 5} tick={{fontSize: 14}} dataKey="time" tickFormatter={getStartOfHour}/>
                        <YAxis axisLine={false}/>
                        {isGeneralTile ? <Tooltip content={<CustomTooltip/>}/> : <Tooltip content={<HourlyTooltip/>} />}
                        <Line dot={false} type="monotone" dataKey="swimmer_count" stroke="#57A6DB"/>
                    </LineChart>
                </ResponsiveContainer>
            }
        </div>
    </div>
}
SwimmersCountHistoryTile.propTypes = {
    data: PropTypes.object.isRequired,
    timeFrame:PropTypes.object.isRequired,
    isDrillDownActive: PropTypes.bool,
    poolName: PropTypes.string
}
export default SwimmersCountHistoryTile