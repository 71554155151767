import './Footer.scss'
import {ReactComponent as LxIcon} from "../../public/images/lx_logo.svg";
import {useTranslation} from "react-i18next";
import {useState} from "react";

const Footer = () => {
    const [t] = useTranslation()

    function hideFooter() {
        const path = window.location.pathname;
        return (path.includes('reports') ||  path.includes('monitor'));
    }

    const [show] = useState(!hideFooter())
    return show && <div id={'footer'}>
        <div className={'bottom-label'}>
            <div className={'prefix'}>{t('poweredBy')}</div>
            <LxIcon/>
        </div>
    </div>
}
Footer.propTypes = {}
export default Footer