import PropTypes from "prop-types";
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import './ReportsSortLabel.scss'

const ReportsSortLabel = props => {
    const handleArrowUpClick = e => {
        props.onClick(e, 'asc')
    }
    const handleArrowDownClick = e => {
        props.onClick(e, 'desc')
    }
    return (
        <div id={'sort-label'}>
            <div className={'title-text'}>{props.label}</div>
            &nbsp;
            &nbsp;
            <div className={'arrows-container'}>
                <KeyboardArrowUp className={(props.active && props.direction==='asc'? 'active' : 'arrow')} fontSize={'small'} onClick={handleArrowUpClick}/>
                <KeyboardArrowDownIcon className={(props.active && props.direction==='desc'? 'active' : 'arrow')} fontSize={'small'} onClick={handleArrowDownClick}/>
            </div>
        </div>
    )
}

ReportsSortLabel.propTypes = {
    active: PropTypes.bool.isRequired,
    direction: PropTypes.oneOf(['asc', 'desc']).isRequired,
    onClick: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired
};

export default ReportsSortLabel