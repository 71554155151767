import './TempAlertDisableAccordion.scss'
import Button from '@mui/material/Button';
import "react-international-phone/style.css";
import * as React from 'react';
import {useState} from 'react';
import {
    Accordion,
    AccordionActions,
    AccordionDetails,
    AccordionSummary,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    styled,
    TextField
} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import PropTypes from "prop-types";
import RemoveIcon from '@mui/icons-material/Remove';
import {useTranslation} from "react-i18next";
import {shortString, time, timeFrames15minutes} from "../../../../utils/utils";
import DateSelector from "../../../DateSelector/DateSelector";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";


const CssTextField = styled(TextField)({
    '& input': {
        color: "white",
        height: '22px',
        borderTop: '1px solid #3D5484',
        lineHeight: '28px',
        borderRadius: '5px 5px 0px 0px',
    }, '& label.Mui-focused': {
        color: '#3D5484', marginTop: '-5px',
    }, '& label.MuiInputLabel-shrink': {
        color: '#3D5484', marginTop: '-5px',
    }, '& label': {
        color: '#3D5484',
    }, '& .MuiInput-underline:after': {
        borderBottomColor: '#3D5484',
    }, '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#3D5484',
        }, '&:hover fieldset': {
            borderColor: '#3D5484',
        }, '&.Mui-focused fieldset': {
            borderColor: '#3D5484', borderWidth: '1px',
        }, '&.Mui-error': {
            '&:hover fieldset': {
                borderColor: "#d32f2f"
            }
        },
    },

});
const TempAlertDisableAccordion = props => {
    const [expanded, setExpanded] = useState(false)
    const [t] = useTranslation()
    const [reason, setReason] = useState('')
    const [selectedPool, setSelectedPool] = useState('')
    const [selectedDate, setSelectedDate] = useState(null)
    const [selectedTime, setSelectedTime] = useState(null)
    const [selectedDuration, setSelectedDuration] = useState('')
    const [openVerifyModal, setOpenVerifyModal] = useState(false)
    const submit = () => {
        setOpenVerifyModal(true)
    }
    const onAccordionChange = (event, expanded) => {
        setExpanded(expanded)
        props.handleChange(event, expanded)
    }

    function handlePoolSelection(ev) {
        setSelectedPool(ev.target.value)
    }

    function clear() {
        setSelectedPool(null)
        setReason('')
        setSelectedTime('')
        setSelectedDuration('')
    }

    function handleDurationSelection(e) {
        const value = e.target.value
        setSelectedDuration(timeFrames15minutes.find(e => e.value === value)?.value || '')

    }

    let handleDateSelection = (date) => {
        setSelectedDate(date)
    };

    function handleTimeSelection(time) {
        setSelectedTime(time.target.value)
    }

    async function saveNewRegime() {
        setOpenVerifyModal(false)
        const res = await props.submit({
            selectedPool: selectedPool.title,
            selectedPoolId: selectedPool.id,
            selectedTime,
            selectedDate,
            selectedDuration,
            reason
        })
        if (res) {
            clear()
        }
    }

    return (<div id={'main-pane'}>
        <Dialog open={openVerifyModal} onClose={() => {
            setOpenVerifyModal(false)
        }} aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                    sx: {
                        backgroundColor: "#001333",
                        color: "#EAFBFF",
                        border: "1px solid #3D5484",
                        padding: "28px 24px 28px 24px",
                        borderRadius: "5px"
                    }
                }}
        >
            <DialogTitle id="alert-dialog-title">
                <div style={{textAlign: "center"}}>
                    {t("areYouSureToSilenceAlertsIn") + " " + selectedPool?.title + '?'}
                </div>

            </DialogTitle>
            <DialogActions>
                <div className={"delete-actions-row"}>
                    <Button onClick={saveNewRegime}
                            variant="outlined"
                            style={{
                                color: "#FF4D4D",
                                borderColor: "#FF4D4D",
                                fontSize: "14px",
                                fontFamily: "Work Sans",
                                textTransform: "none"
                            }}>
                        <b>{t('silenceAlerts')}</b>
                    </Button>
                    <Button onClick={() => {
                        setOpenVerifyModal(false)
                    }}
                            size="small"
                            style={{
                                color: "#EAFBFF",
                                fontSize: "14px",
                                fontFamily: "Work Sans",
                                textTransform: "none"
                            }}>
                        <b>{t('cancel')}</b>
                    </Button>
                </div>
            </DialogActions>
        </Dialog>
        <Accordion
            expanded={expanded}
            onChange={onAccordionChange}
            elevation={6}
            sx={{minHeight: "1px", height: "fit-content"}}>
            <AccordionSummary expandIcon={expanded ? <RemoveIcon sx={{color: "#EAFBFF"}}/> :
                <AddIcon sx={{color: "#EAFBFF"}}/>}>{t('alertSilenceOnDemand')}</AccordionSummary>
            <AccordionDetails>
                <div className={'acc-pane'}>
                    <div className={'text-fields-row'}>
                        <FormControl style={{width: '210px'}}>
                            <InputLabel style={{marginTop: '-7px'}}
                                        id={'pool-select-label'}>{t('selectPool')}</InputLabel>
                            <Select
                                size={'small'}
                                onChange={handlePoolSelection}
                                labelId={'pool-select-label'}
                                id={'pool-select'}
                                value={selectedPool}
                                MenuProps={{
                                    sx: {
                                        "&& .MuiPaper-root": {
                                            color: '#EAFBFF', backgroundColor: "#00173D", border: '1px solid #3D5484'
                                        }

                                    }
                                }}
                                renderValue={(value) => {
                                    return <div className={'select-value'}
                                                style={{paddingLeft: '15px'}}>{shortString(value.title, 10)}</div>
                                }}
                                sx={{
                                    '.MuiOutlinedInput-notchedOutline': {
                                        borderColor: '#3D5484',
                                    }, '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        borderColor: '#3D5484',
                                    }, '&:hover .MuiOutlinedInput-notchedOutline': {
                                        borderColor: '#3D5484',
                                    }, '.MuiSelect-icon': {
                                        color: '#3D5484'
                                    }

                                }}
                                variant={'outlined'}>
                                {props.pools.map(e => <MenuItem key={e.id} value={e}>
                                    <div style={{
                                        color: '#EAFBFF', backgroundColor: '#00173D', paddingLeft: '0px'
                                    }}>{e.title}</div>
                                </MenuItem>)}
                            </Select>
                        </FormControl>

                        {expanded && <DateSelector
                            onChange={handleDateSelection}
                            date={selectedDate}/>}
                        <FormControl style={{width: '150px'}}>
                            <InputLabel style={{marginTop: '-7px'}}
                                        id={'time-select-label'}>{t('time')}</InputLabel>
                            <Select
                                size={'small'}
                                onChange={handleTimeSelection}
                                labelId={'time-select-label'}
                                id={'pool-select'}
                                value={selectedTime}
                                MenuProps={{

                                    sx: {
                                        "&& .MuiPaper-root": {
                                            color: '#EAFBFF', backgroundColor: "#00173D", border: '1px solid #3D5484'
                                        }

                                    }
                                }}
                                renderValue={(value) => {
                                    const val = time.find(e => e.value === value)
                                    return <div className={'select-value'}
                                    >{val.label}</div>
                                }}
                                sx={{
                                    '.MuiOutlinedInput-notchedOutline': {
                                        borderColor: '#3D5484',
                                    }, '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        borderColor: '#3D5484',
                                    }, '&:hover .MuiOutlinedInput-notchedOutline': {
                                        borderColor: '#3D5484',
                                    }, '.MuiSelect-icon': {
                                        color: '#3D5484'
                                    }

                                }}
                                variant={'outlined'}>
                                {time.map(e => <MenuItem key={e.label} value={e.value}>
                                    <div style={{
                                        color: '#EAFBFF', backgroundColor: '#00173D', paddingLeft: '0px'
                                    }}>{e.label}</div>
                                </MenuItem>)}
                            </Select>
                        </FormControl>

                        <FormControl style={{width: '227px'}}>
                            <InputLabel style={{marginTop: '-7px'}}
                                        id={'duration-select-label'}>{t('duration')}</InputLabel>
                            <Select
                                size={'small'}
                                onChange={handleDurationSelection}
                                labelId={'duration-select-label'}
                                id={'pool-select'}
                                value={selectedDuration || ''}
                                MenuProps={{
                                    sx: {
                                        "&& .MuiPaper-root": {
                                            color: '#EAFBFF', backgroundColor: "#00173D", border: '1px solid #3D5484'
                                        }

                                    }
                                }}
                                renderValue={(value) => {
                                    const val = timeFrames15minutes.find(e => e.value === value)
                                    return <div className={'select-value'}
                                    >{val.label}</div>
                                }}
                                sx={{
                                    '.MuiOutlinedInput-notchedOutline': {
                                        borderColor: '#3D5484',
                                    }, '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        borderColor: '#3D5484',
                                    }, '&:hover .MuiOutlinedInput-notchedOutline': {
                                        borderColor: '#3D5484',
                                    }, '.MuiSelect-icon': {
                                        color: '#3D5484'
                                    }

                                }}
                                variant={'outlined'}>
                                {timeFrames15minutes.map(e => <MenuItem key={e.value} value={e.value}>
                                    <div style={{
                                        color: '#EAFBFF', backgroundColor: '#00173D', paddingLeft: '0px'
                                    }}>{e.label}</div>
                                </MenuItem>)}
                            </Select>
                        </FormControl>

                        <CssTextField onFocus={() => {
                        }}
                                      inputProps={{maxLength: 30}}
                                      value={reason}
                                      onChange={(ev) => {
                                          setReason(ev.target.value)
                                      }}
                                      style={{width: "220px"}}
                                      label={t('reason')}
                                      size={'small'}>

                        </CssTextField>
                    </div>
                </div>
            </AccordionDetails>
            <AccordionActions>
                <div className={"actions-row"}>
                    <Button onClick={() => {
                        clear()
                    }}
                            size="small"
                            style={{
                                color: "#EAFBFF", fontSize: "14px", fontFamily: "Work Sans", textTransform: "none"
                            }}
                            sx={{fontWeight: "700"}}>
                        {t('clear')}
                    </Button>
                    <Button onClick={submit}
                            size="small"
                            disabled={props.isSaveDisabled || !reason || (reason && reason.length < 2) || !selectedPool || !selectedDate || !selectedDuration}
                            sx={{fontWeight: "1000"}}
                            variant={'contained'}
                            color={'primary'}
                            className={'save-button'}
                    >
                        {t('save')}
                    </Button>
                </div>
            </AccordionActions>
        </Accordion>
    </div>)
}
TempAlertDisableAccordion.propTypes = {
    handleChange: PropTypes.func.isRequired,
    pools: PropTypes.array.isRequired,
    isSaveDisabled: PropTypes.bool.isRequired,
    submit: PropTypes.func.isRequired
}
export default TempAlertDisableAccordion


