import './UserMenu.scss'
import React, { useState } from 'react'
import DropdownMenu from './DropdownMenu';
import PropTypes from "prop-types";



const UserMenu = props => {

    const [menuState, setMenuState] = useState(false)
    const [menuPos, setMenuPos] = useState(null)
    const [username] = useState(props.username || '')

    const handleMenuOpen = event => {
      setMenuPos(event.currentTarget)
      setMenuState(true)
    }

    const handleMenuClose = () => {
      setMenuState(false)
    }


    return <div id={'menu-selector'} style={props.styles}>
      <div className='user-container'>
          <div onClick={handleMenuOpen} className='user-image'>{username.substring(0,1).toLocaleUpperCase()}</div>
        <DropdownMenu adminMode={props.adminMode} setAdminMode={props.setAdminMode} open={menuState} menuPos={menuPos} handleMenuClose={handleMenuClose}/>
      </div>
    </div>
}
UserMenu.propTypes = {
    username: PropTypes.string.isRequired,
    setAdminMode: PropTypes.func.isRequired,
    adminMode: PropTypes.bool.isRequired,
    styles: PropTypes.object
}
export default UserMenu