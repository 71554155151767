import PropTypes from "prop-types";
import './TablePagination.scss'
import {Box} from "@mui/material";
import {useTranslation} from "react-i18next";
import {Pagination, PaginationItem} from "@mui/material";

function TablePaginationActions(props) {
    const {count, page, rowsPerPage, onPageChange} = props;
    const [t] = useTranslation();

    const handlePaginationChange = (event, item) => {
        onPageChange(event, item - 1)
    }

    const renderPaginationItem = (item) => {
        let className = item.page !== null ? 'border-cube' : ''
        className = item.page === page + 1 && item.type !== 'next' ? 'border-cube-active': className
        return <PaginationItem
            className={className} {...item}
        />
    }

    const handlePageNumberInput = (event) => {
        if (event.target.value > 0 && event.target.value <= Math.ceil(count / rowsPerPage)) {
            onPageChange(event, event.target.value - 1)
        }
    }
    return (
        <Box sx={{flexShrink: 0, ml: 2.5}} id={'table-pagination-actions'}>
            <Pagination renderItem={renderPaginationItem} page={page + 1} onChange={handlePaginationChange} count={Math.ceil(count / rowsPerPage)} variant="outlined" shape="rounded" />
            &nbsp;
            <div className={'actions-plain-text'}>
                {t('jumpTo')}
            </div>
            &nbsp;
            <input type={'number'} onBlur={handlePageNumberInput} defaultValue={page + 1} className={'page-input'}>

            </input>
        </Box>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

export default TablePaginationActions;