import LifeguardAccordion from "../LifeguardAccordion/LifeguradAccordion";
import LifeguardList from "../LifeguardList/LifeguardList";
import {useEffect, useState} from "react";
import './LifeguardTab.scss';
import {getLifeguardsBySiteId} from "../../../../api/api";
import PropTypes from "prop-types";
import {clearSpaces, getNodeContext} from "../../../../utils/utils";
const LifeguardsTab = props => {
    const [isAccordionExpanded,setIsAccordionExpanded] = useState(false)
    const [lifeguards,setLifeguards] = useState(null)
const isAdmin = getNodeContext().permissions === 'administrator'
    const fetchLifeguards = async () =>{
        if(props.site){
            if(props.site.id){
                getLifeguardsBySiteId(props.site.id).then((res)=>{setLifeguards(res.data)})
            }
        }
    }

    useEffect(()=>{
        fetchLifeguards()
    },[])

    const reload = () => {
        fetchLifeguards()
    }
    const doesPinCodeExist = (pinCode) => {
        return lifeguards?.find((lifeguard) => lifeguard.pin === pinCode)
    }
    const doesPhoneNumberExist = (phoneNumber) =>{
        return lifeguards?.find((lifeguard) => clearSpaces(lifeguard.phone) === clearSpaces(phoneNumber))
    }
    const doesEmailExist = (email) =>{
        return lifeguards?.find((lifeguard)=>lifeguard.email === email)
    }

    return (
        <div>
            { isAdmin && <LifeguardAccordion doesEmailExist={doesEmailExist}
                                 doesPhoneNumberExist={doesPhoneNumberExist}
                                 reload={reload}
                                 doesPinCodeExist={doesPinCodeExist}
                                 handleChange={(event, expanded) => {
                                     setIsAccordionExpanded(!!expanded)
                                 }}>

            </LifeguardAccordion>}
            <LifeguardList reload={reload} lifeguards={lifeguards} isAccordionExpanded={isAccordionExpanded} site={props.site}/>
        </div>
    )
}
LifeguardsTab.propTypes = {
    site: PropTypes.object.isRequired
}
export default LifeguardsTab


