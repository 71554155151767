import React, {useEffect} from 'react'
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import LoginPage from "./Components/LoginPage/LoginPage";
import Dashboard from "./Components/Dashboard/Dashboard";
import ResetPasswordFull from "./Components/ResetPassword/ResetPassword";
import ResetPasswordCredFull from "./Components/ResetPassword/ResetPasswordNewCred";
import { isMobile } from 'react-device-detect';
import './App.scss'
import Footer from "./Components/Footer/Footer";
import TagManager from "react-gtm-module/dist/TagManager";
import { hotjar } from 'react-hotjar';
import {authenticateNode, getNodeContext} from "./utils/utils";
import Page404 from "./Components/Page404/Page404";
import json from "../package.json"
import ReportsPage from "./Components/ReportsPage/ReportsPage";
import mixpanel from 'mixpanel-browser';
import MonitorPage from "./Components/MonitorPage/MonitorPage";

function App() {
    console.log(`%c Welcome to the Lynxight dashboard, version - ${json.version}`,"color: #57A6DB; font-family:David; font-size: 32px")

    useEffect(()=>{
        process.env.NODE_ENV==='production' && TagManager.initialize({ gtmId: 'G-V0BG4TCD13' });
        process.env.NODE_ENV==='production' && console.log("Google analytics monitoring", )
    },[])
    hotjar.initialize(process.env.REACT_APP_HOTJAR_ID,process.env.REACT_APP_HOTJAR_SNIPPET_VERSION)

    if(isMobile){
        window.location.assign(process.env.REACT_APP_MOBILE_PATH +window.location.pathname)
    }

    const authenticate = async (username, password) => {
        const context = await authenticateNode(username, password)
        try{
            try {
                mixpanel.init(process.env.REACT_APP_MIXPANEL_KEY, { debug: true, track_pageview: true, persistence: 'localStorage' });
                mixpanel.identify(username)
                mixpanel.register({'Site Name':context.siteName})
            }catch (ex){
                console.log("Error here in mixpanel", ex)
            }
            hotjar.identify(username,{
                email:context.email || '',
                site:context.siteName || "none",
                organization: context.orgName ||"none",
                isStaff: context.isStaff,
                environment:process.env.NODE_ENV
            })
        }catch (e) {
            console.log("Error authenticating ", e.response)
            return  e.response.status
        }

    }
const context = getNodeContext()
    if (!context) {
        return (
            <BrowserRouter id='app'>
                <Routes>
                    <Route path="/" element={<LoginPage redirectPath={window.location.pathname+window.location.search} submit={authenticate}/>}/>
                    <Route path="reports" element={<LoginPage  redirectPath={window.location.pathname+window.location.search} submit={authenticate}/>}/>
                    <Route path="dashboard" element={<LoginPage  redirectPath={window.location.pathname+window.location.search} submit={authenticate}/>}/>
                    <Route path="monitor" element={<LoginPage  redirectPath={window.location.pathname+window.location.search} submit={authenticate}/>}/>
                    <Route path="reset_password" element={<ResetPasswordFull/>}/>
                    <Route path="reset_password/:token" element={<ResetPasswordCredFull/>}/>
                    <Route path="*" element={<Page404/>}/>
                </Routes>
            </BrowserRouter>
        )
    }else  return (
            <BrowserRouter id='app'>
                <Routes>
                    <Route path="/" element={<Dashboard context={context}/>}/>
                    <Route path="reports" element={<ReportsPage />}/>
                    <Route path="monitor" element={<MonitorPage />}/>
                    <Route path="dashboard" element={<Dashboard context={context}/>}/>
                    <Route path="*" element={<Page404 />}/>
                </Routes>
                <Footer />
            </BrowserRouter>

    );
}

export default App;
