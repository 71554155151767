import './Tabs.scss'
import React, {useEffect, useState} from "react";
import PropTypes from 'prop-types'
import {shortString} from "../../utils/utils";
import {useNavigate} from 'react-router-dom';
import {useTranslation} from "react-i18next";

const Tabs = props => {
    const navigate = useNavigate();
    const [t] = useTranslation()
    const handleSelection = (index) => {
        navigate({search: "?tab=" + index})
        props.updateSelectedTab(index)
    }
    useEffect(() => {

    }, )
    const data = props.children.length === 2 ? props.children.filter(e => e.id !== -1) : props.children
    return <div id="tabs">
        <div className={'tabs-header'}>
            {data.map((e, index) =>
                <div test-id={`tab-${index} `} key={'tabs' + index + e.isAlertsTempDisabled}
                     className={`box ${props.selected === index ? 'selected-box' : ''} ${e.isAlertsTempDisabled? 'alerts-temp-disabled' : ''}`}
                     onClick={() => handleSelection(index)}>
                    <div className={'inner-box'}>
                        {e.id !== -1 && <div className={`dot ${e.status}`}/>}
                        <div
                            className={'label'}>{e.id === -1 ? t('generalPool') : props.selected === index ? e.dashboard_name : shortString(e.dashboard_name, 14)}</div>
                    </div>
                </div>
            )}
        </div>
        <div className={'content'}>
            {data[props.selected] && data[props.selected].pane}
        </div>
    </div>
}
Tabs.propTypes = {
    children: PropTypes.array.isRequired,
    selected: PropTypes.number,
    updateSelectedTab: PropTypes.func.isRequired
}
export default Tabs