import './ImageCarousel.scss'
import PropTypes from "prop-types";
import {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {ReactComponent as EmptyImageIcon} from "./empty-image.svg";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
const ImageCarousel = props => {
    const [t] = useTranslation()
    const prevIndex = useRef( 0);
    let selectedIndex = props.selectedIndex || 0

    useEffect(() => {
        selectedIndex = selectedIndex === -1 ? 0 : selectedIndex
        if (props.selectedIndex !== prevIndex.current){
            prevIndex.current = selectedIndex
                animateImageMove(selectedIndex)
        }
    }, [props.selectedIndex])

    function animateImageMove(newIndex){
        props.items.forEach((item, i) => {
            const element = document.getElementById('alert-index-' + i)
            element.className = ('card')
            if (i === newIndex) {
                element.classList.add('selected')
            } else if (i === newIndex - 1) {
                element.classList.add('prev')
            } else if (i === newIndex + 1) {
                element.classList.add('next')
            } else {
                element.classList.add('inactive')
            }
        })
    }
    function handleAlertChange(inc,_selectedInd=-1) {
        let newIndex = _selectedInd===-1?(selectedIndex + inc):_selectedInd
        if (newIndex < 0) {
            newIndex = 0
        } else if (newIndex >= props.items.length) {
            newIndex = props.items.length - 1
        }
        selectedIndex = newIndex
        props.selectEvent(newIndex)

    }

    function processMouseClickOnImage(index) {
        if (selectedIndex<index){
            handleAlertChange(1)
        }else if (selectedIndex>index) {
            handleAlertChange(-1)
        }
    }

    return <div id={'image-carousel'}>
        {
            props.items && props.items.length ?
                <div className={'container'}>
                    { props.items && props.items.length > 1 && <div className={'left-right-button'}  onClick={() => handleAlertChange(-1)}>
                        <div className='button-icon'>
                            < ArrowBackIosNewIcon style={{ marginRight: "4px",color:"inherit"}}/>
                        </div>
                    </div>}
                    <div className={`image-view ${props.items && props.items.length === 1?'left55':''}`}>
                        {props.items.map((alert, i) => {
                            return <div id={'alert-index-' + i}
                                        onClick={()=>{processMouseClickOnImage(i)}}
                                        className={`card ${i === prevIndex.current ? 'selected' : i === prevIndex.current - 1 ? 'prev' : i === prevIndex.current + 1 ? 'next' : 'inactive'}`}
                                        key={Math.random() + "-key"}>
                                <div className={'alert-info'}>
                                    <div className={'inner-text-box'}>
                                        <div className={`alert-type ${alert.description.toLowerCase()}`}/>
                                        <div className={'data'}>{t(alert.description.toLowerCase())}</div>
                                    </div>
                                    <div className={'inner-text-box'}>
                                        <AccessTimeIcon style={{color:'#00173D'}}/>
                                        <div className={'data'}>{alert.time}</div>
                                    </div>
                                </div>
                                {alert.image?<img className={'alert-image'} src={'data:image/png;base64,' + alert.image} alt='image'/>:
                                    <EmptyImageIcon  className={'no-image-icon'}/>
                                }
                            </div>
                        })}
                    </div>
                    { props.items && props.items.length>1 &&<div className={'left-right-button'} style={{transform: 'rotateZ(180deg)',right:0}} onClick={() => handleAlertChange(1)}>
                        <div className='button-icon' >
                            < ArrowBackIosNewIcon style={{ marginRight: "4px",color:"inherit"}}/>
                        </div>
                    </div>}
                </div> :
                <div className={'empty-image-view'}>
                    <EmptyImageIcon/>
                </div>
        }
    </div>
}
ImageCarousel.propTypes = {
    items: PropTypes.array.isRequired,
    selectedIndex: PropTypes.number,
    selectEvent: PropTypes.func.isRequired
}
export default ImageCarousel