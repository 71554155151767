import './LifeguardAccordion.scss'
import Button from '@mui/material/Button';
import "react-international-phone/style.css";
import * as React from 'react';
import {
    Accordion, AccordionActions,
    AccordionDetails,
    AccordionSummary, styled, TextField
} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import PropTypes from "prop-types";
import * as EmailValidator from "email-validator";
import parsePhoneNumber from "libphonenumber-js";
import {createLifeguard} from "../../../../api/api";
import {useState} from "react";
import {clearSpaces, getNodeContext, getOrgCountry} from "../../../../utils/utils";
import RemoveIcon from '@mui/icons-material/Remove';
import CustomPhoneInput from "../../../CustomPhoneInput/CustomPhoneInput";
import {showSnackbar} from "../../../../utils/SnackbarUtils";
import {useTranslation} from "react-i18next";

const CssTextField = styled(TextField)({
    '& input':{
        color:"white",
    },
    '& label.Mui-focused': {
        color: '#3D5484',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#3D5484',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#3D5484',
        },
        '&:hover fieldset': {
            borderColor: '#3D5484',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#3D5484',
            borderWidth:'1px',
        },
        '&.Mui-error':{
            '&:hover fieldset':{
                borderColor:"#d32f2f"
            }
        },
    },

});
const LifeguardAccordion = props => {
    const [lifeguard,setLifeguard] = useState({name:"",lastName:"",acronym:"",email:"",phone:"",pin:""})
    const [errors,setErrors] = useState(null)
    const [expanded,setExpanded] = useState(false)
    const [t] = useTranslation()

    const validateLifeguard = (lifeguard) => {
        const pinCodeRegex = new RegExp(/^\d{3}$/)
        const namesRegex = new RegExp(/^([\p{L}0-9]+(?:[ -]?[\p{L}0-9]+)*){2,50}$/u)
        const acronymRegex = new RegExp(/^[\p{L}0-9]{2}$/u)
        let errors = {}
        let keys = Object.keys(lifeguard)
        keys.forEach((key)=>{
            switch (key){
                case "name":
                    if(!lifeguard[key]){
                        errors[key] = t('required')
                    }
                    else if(!namesRegex.test(lifeguard[key])){
                        errors[key] = t('invalid')
                    }
                    break;
                case "lastName":
                    if(!lifeguard[key]){
                        errors[key] = t('required')
                    }
                    else if(!namesRegex.test(lifeguard[key])){
                        errors[key] = t('invalid')

                    }
                    break;
                case "pin":
                    if(!lifeguard[key]){
                        errors[key] = t('required')
                    }
                    else if(!pinCodeRegex.test(lifeguard[key])){
                        errors[key] = t('invalid')
                    }
                    else if(props.doesPinCodeExist(lifeguard[key])){
                        errors[key] = t('alreadyExists')
                    }
                    else if(lifeguard[key]==="555"){
                        errors[key] = t('alreadyExists')
                    }
                    break;
                case "email":
                    if(!lifeguard[key]){
                        errors[key] = t('required')
                    }
                    else if(!EmailValidator.validate(lifeguard[key])){
                        errors[key] = t('invalid')
                    }
                    else if(props.doesEmailExist(lifeguard[key])){
                        errors[key] = t('alreadyExists')
                    }
                    break;
                case "acronym":
                    if(!lifeguard[key]){
                        errors[key] = t('required')
                    }
                    else if(!acronymRegex.test(lifeguard[key])){
                        errors[key] = t('invalid')
                    }
                    break;
                case "phone":
                    if(!lifeguard[key]){
                        errors[key] = t('required')
                    }
                    else{
                        let phoneNumber = parsePhoneNumber(lifeguard[key])
                        if(phoneNumber&&!phoneNumber.isValid()){
                            errors[key] = t('invalid')
                        }
                        else if(!phoneNumber){
                            errors[key] = t('invalid')
                        }
                        else if(props.doesPhoneNumberExist(lifeguard[key])){
                            errors[key] = t('alreadyExists')
                        }
                    }
                    break;
                default:
                    break;
            }
        })
        return errors
    }
    const handleLifeguardInput = (key,newVal) =>{
        let temp = {...lifeguard}
        temp[key] = newVal
        setLifeguard(temp)
    }

    const handleCreateLifeguard = async () => {
        let context = getNodeContext()
        let lifeguardCreate = {...lifeguard}
        lifeguardCreate['phone'] = clearSpaces(lifeguardCreate['phone'])
        let validationResult = validateLifeguard(lifeguardCreate)
        if(Object.keys(validationResult).length){
            setErrors(validationResult)
            setExpanded(true)
            return
        }
        let res = await createLifeguard(context.siteId,lifeguardCreate)
        if(res!=201){
            showSnackbar(t('errorCreatingLifeguard'),"error",5000)

            setExpanded(true)
            return
        }
        showSnackbar( t('lifeguardCreated'),"success",5000)
        props.reload()
        setExpanded(false)
        props.handleChange({},false)
        setLifeguard({name:"",lastName:"",acronym:"",email:"",phone:"",pin:""})
    }
    const getError = (key) => {
        return errors&&JSON.stringify(errors)!=="{}"?errors[key]:""
    }
    const clearError = (key) =>{
        if(errors){
            if(errors[key]){
                let temp = {...errors}
                delete temp[key]
                setErrors(temp)
            }
        }
    }
    const onAcronymChange = (event) => {
        let val = event.target.value.toUpperCase().replace(/[^\p{L}0-9]/u,"")
        handleLifeguardInput("acronym",val)
    }
    const onPINChange = (event) => {
        let val = event.target.value.replace(/[^0-9]/gi,"")
        handleLifeguardInput("pin",val)
    }

    const onPhoneChange = (value) => {
        handleLifeguardInput("phone",value)
    }

    const onAccordionChange = (event,expanded) => {
        setExpanded(expanded)
        props.handleChange(event,expanded)
        setErrors({})
    }
    return (
        <div className={'lifeguard-creation-pane'}>
            <Accordion expanded={expanded} onChange={onAccordionChange} elevation={6} sx={{minHeight:"1px",height:"fit-content"}}>
                <AccordionSummary expandIcon={expanded?<RemoveIcon sx={{color:"#EAFBFF"}}/>:<AddIcon sx={{color:"#EAFBFF"}}/>}>{t('addLifeguard')}</AccordionSummary>
                <AccordionDetails>
                    <div className={'lifeguard-pane'}>
                        <div className={'text-fields-row'}>
                            <CssTextField onFocus={()=>{clearError("name")}}
                                          error={getError("name")}
                                          helperText={getError("name")}
                                          inputProps={{ maxLength: 50}}
                                          value={lifeguard.name}
                                          onChange={
                                            (event)=>{
                                              handleLifeguardInput("name",event.target.value)
                                            }
                                          }
                                          style={{width:"53%"}}
                                          label={t('firstName')}
                                          size={'small'}></CssTextField>

                            <CssTextField onFocus={()=>{clearError("lastName")}}
                                          inputProps={{ maxLength: 50}}
                                          error={getError("lastName")}
                                          helperText={getError("lastName")}
                                          value={lifeguard.lastName}
                                          onChange={
                                            (event)=>{
                                                handleLifeguardInput("lastName",event.target.value)
                                            }
                                          }
                                          style={{width:"53%"}}
                                          label={t('lastName')}
                                          size={'small'}></CssTextField>

                            <CssTextField inputProps={{ maxLength: 2}}
                                          onFocus={()=>{clearError("acronym")}}
                                          error={getError("acronym")}
                                          helperText={getError("acronym")}
                                          value={lifeguard.acronym}
                                          onChange={onAcronymChange}
                                          label={t('acronym')}
                                          size={'small'}
                                          style={{width:"140px"}}></CssTextField>
                        </div>
                        <div className={'text-fields-row'}>
                            <CssTextField onFocus={()=>{clearError("email")}}
                                          inputProps={{ maxLength: 320}}

                                          error={getError("email")}
                                          helperText={getError("email")}
                                          value={lifeguard.email}
                                          onChange={
                                            (event)=>{
                                                handleLifeguardInput("email",event.target.value)
                                            }
                                          }
                                          style={{width:"53%"}}
                                          label={t('email')}
                                          size={'small'}></CssTextField>
                            <CustomPhoneInput onFocus={()=>{clearError("phone")}}
                                              error={getError("phone")}
                                              defaultCountry={getOrgCountry()}
                                              value={lifeguard.phone}
                                              onChange={onPhoneChange}
                                              style={{width:"53%",height:"40px"}}/>
                            <CssTextField inputProps={{ maxLength: 3 }}
                                          onFocus={()=>{clearError("pin")}}
                                          error={getError("pin")}
                                          helperText={getError("pin")}
                                          value={lifeguard.pin}
                                          onChange={onPINChange}
                                          label={t('pinCode')}
                                          size={'small'}
                                          sx={{width:"140px"}}></CssTextField>
                        </div>
                    </div>

                </AccordionDetails>
                <AccordionActions>
                    <div className={"actions-row"}>
                        <Button onClick={
                                    ()=>{
                                        setLifeguard({name:"",lastName:"",acronym:"",email:"",phone:"",pin:""});
                                        setErrors({})
                                    }
                                }
                                size="small"
                                style={{color:"#EAFBFF",fontSize:"14px",fontFamily:"Work Sans",textTransform:"none"}}
                                sx={{fontWeight:"700"}}>
                            {t('clear')}
                        </Button>
                        <Button onClick={()=>{handleCreateLifeguard()}}
                                size="small"
                                sx={{fontWeight:"1000"}}
                                style={{backgroundColor:"#57A6DB",color:"#00173D",fontSize:"14px",fontFamily:"Work Sans",textTransform:"none",padding:"5px 15px"}}>
                            {t('addLifeguard')}
                        </Button>
                    </div>
                </AccordionActions>
            </Accordion>
        </div>
    )
}
LifeguardAccordion.propTypes = {
    handleChange: PropTypes.func,
    doesPinCodeExist: PropTypes.func.isRequired,
    reload: PropTypes.func.isRequired,
    doesPhoneNumberExist: PropTypes.func.isRequired,
    doesEmailExist: PropTypes.func.isRequired
}
export default LifeguardAccordion


