import './CustomPhoneInput.scss'
import { PhoneInput ,defaultCountries,} from "react-international-phone";
import PropTypes from "prop-types";
import React from 'react';
import {clearSpaces, getCountryCodeByName} from "../../utils/utils";

const CustomPhoneInput = props => {
    const getCountryPrefix = (country) =>{
        let code = country||getCountryCodeByName(props.defaultCountry).toLowerCase()
        const c = defaultCountries.find((item)=>item[1]=== code )
        return c?c[2]:""
    }
    const onChange = (event,country) => {
        let code = getCountryPrefix(country)
        if(`+${code} `===  event || `+${code}` ===  event){
            props.onChange("")
            return
        }
        props.onChange(event)
    }
    const getBorderColor = () => {
        return props.error?"#d32f2f":"#3D5484"
    }
    const getDefaultCountryCode= () => {
        return props.defaultCountry?getCountryCodeByName(props.defaultCountry).toLowerCase():"il"
    }
    return (
        <div onFocus={props.onFocus} style={{...props.style}}>
            <PhoneInput forceDialCode
                        onChange={onChange}
                        countrySelectorStyleProps={{buttonStyle:{borderColor:getBorderColor()}}}
                        inputStyle={{borderColor:getBorderColor()}}
                        value={props.value || ''}
                        placeholder={"Phone Number"}
                        defaultCountry={getDefaultCountryCode()}
                        style={{height:"100%",width:"100%"}}/>
            <p className={'phone-error-msg'}>{props.error}</p>
        </div>
    )
}
CustomPhoneInput.propTypes = {
    style:PropTypes.object,
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    defaultCountry: PropTypes.string,
    error: PropTypes.bool,
    onFocus:PropTypes.func.isRequired
}


export default CustomPhoneInput