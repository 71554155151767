import './PoolCapacityTileStatic.scss'
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";

const PoolCapacityTileStatic = props => {
    const radius = 70
    const [t] = useTranslation()
    const perimeterFull = Math.PI * radius * 2
    const currentPerimeter = perimeterFull * props.data.capacity
    return <div id={'pool-capacity-tile-static'}>
       <div className={'label-container'}>
           <div className={'count-label'}>{props.data.locations.length}</div>
           <div className={'title-inner'}>{t('swimmers')}</div>
       </div>
        <svg id={'svg-area'}>
            <g transform="translate(0 0)">
                <g strokeLinecap="round">
                    <svg className="circularPercentage" fill="none" width="200" height="200">
                        <circle className="background" fill="none" cx="100" cy="100" stroke="#F9F7F4" strokeWidth="20" r={radius}></circle>
                        <circle className="background" fill="none" cx="100" cy="100" stroke="#EBEBEA" strokeWidth="1" r={radius+8}></circle>
                        <circle className="background" fill="none" cx="100" cy="100" stroke="#EBEBEA" strokeWidth="1" r={radius-8}></circle>
                        <circle className="percentage" fill="none" cx="100" cy="100" stroke="#57A6DB" strokeWidth="8" r={radius} strokeDasharray={[currentPerimeter, perimeterFull]} strokeDashoffset="0"></circle>
                    </svg>
                </g>
            </g>
        </svg>

    </div>
}
PoolCapacityTileStatic.propTypes = {
    data: PropTypes.object.isRequired
}
export default PoolCapacityTileStatic